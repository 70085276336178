/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit } from '@angular/core';
import { CategoryResult } from 'isophi-pdfs-api';

@Component({
  selector: 'app-smart-circle',
  templateUrl: './smart-circle.component.html',
  styleUrls: ['./smart-circle.component.scss']
})
export class SmartCircleComponent implements OnInit {
  @Input() public testType: string;

  @Input() public categoryResult: CategoryResult;

  // Need to move to the server
  /* {evaluation code: level of difficulty} */
  public smartCode = {
    0: 1,
    1: 2,
    2: 3
  };

  public code = 1;

  public ngOnInit(): void {
    if (this.testType === 'D 4-5') {
      if (this.categoryResult.evaluation.title.includes('Přiměřená')) {
        if (+this.categoryResult.percent >= 90) {
          this.code = 3;
        } else {
          this.code = 2;
        }
      }
    } else {
      this.code = this.smartCode[this.categoryResult.evaluation.code];
    }
  }
}
