import { MultiResult, MultiResultTest } from 'isophi-pdfs-api';

export const childResults: MultiResult[] = [
  {
    category: {
      id: 5,
      uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
      name: 'Zrakové vnímání',
      activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
      color: '#AADB6A'
    },
    testResults: [
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Snížená úroveň dovednosti',
          code: 1,
          textColor: '#000000',
          backgroundColor: '#FAB700'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      }
    ]
  },
  {
    category: {
      id: 3,
      uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
      name: 'Prostorová představivost',
      activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
      color: '#76CC7E'
    },
    testResults: [
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Snížená úroveň dovednosti',
          code: 1,
          textColor: '#000000',
          backgroundColor: '#FAB700'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      }
    ]
  },
  {
    category: {
      id: 12,
      uuid: '20728a4c-0e11-429b-ad60-0e03b417597f',
      name: 'Sebeobsluha',
      activeIconUrl: '/media/uploaded/tags/active/category/samostatnost.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/samostatnost.svg',
      color: '#9972B2'
    },
    testResults: [
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Snížená úroveň dovednosti',
          code: 1,
          textColor: '#000000',
          backgroundColor: '#FAB700'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 5-7',
          color: '#EF83A1',
          productCodename: 'test_5-7',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      }
    ]
  },
  {
    category: {
      id: 7,
      uuid: 'b85c92c1-3f1b-49a2-80af-f92dd1ec6fc6',
      name: 'Verbální myšlení',
      activeIconUrl: '/media/uploaded/tags/active/category/rec.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/rec.svg',
      color: '#D39FBE'
    },
    testResults: [
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Snížená úroveň dovednosti',
          code: 1,
          textColor: '#000000',
          backgroundColor: '#FAB700'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      }
    ]
  },
  {
    category: {
      id: 9,
      uuid: 'eedd61ec-80de-441c-8acf-9e6bd79d0bce',
      name: 'Hrubá motorika',
      activeIconUrl: '/media/uploaded/tags/active/category/motorika-hruba.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/motorika-hruba.svg',
      color: '#4585C4'
    },
    testResults: [
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Snížená úroveň dovednosti',
          code: 1,
          textColor: '#000000',
          backgroundColor: '#FAB700'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      }
    ]
  },
  {
    category: {
      id: 4,
      uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
      name: 'Časová orientace',
      activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
      color: '#46C3C6'
    },
    testResults: [
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Snížená úroveň dovednosti',
          code: 1,
          textColor: '#000000',
          backgroundColor: '#FAB700'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      }
    ]
  },
  {
    category: {
      id: 1,
      uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
      name: 'Grafomotorika',
      activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
      color: '#E0937E'
    },
    testResults: [
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Snížená úroveň dovednosti',
          code: 1,
          textColor: '#000000',
          backgroundColor: '#FAB700'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      }
    ]
  },
  {
    category: {
      id: 2,
      uuid: '491575d3-bede-4619-9d31-970701538e92',
      name: 'Předmatematické představy',
      activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
      color: '#F6687C'
    },
    testResults: [
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Snížená úroveň dovednosti',
          code: 1,
          textColor: '#000000',
          backgroundColor: '#FAB700'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      }
    ]
  },
  {
    category: {
      id: 6,
      uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
      name: 'Sluchové vnímání',
      activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
      color: '#F79061'
    },
    testResults: [
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Snížená úroveň dovednosti',
          code: 1,
          textColor: '#000000',
          backgroundColor: '#FAB700'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      }
    ]
  },
  {
    category: {
      id: 11,
      uuid: '05a7f36a-2933-4cfb-90b7-8859d938cc98',
      name: 'Sociální porozumění',
      activeIconUrl: '/media/uploaded/tags/active/category/socialni-chovani.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/socialni-chovani.svg',
      color: '#B57878'
    },
    testResults: [
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Snížená úroveň dovednosti',
          code: 1,
          textColor: '#000000',
          backgroundColor: '#FAB700'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      }
    ]
  },
  {
    category: {
      id: 15,
      uuid: 'c3b91921-9a30-4765-92d2-d62d04deee5b',
      name: 'Emoční zralost',
      activeIconUrl: '/media/uploaded/tags/active/category/emocni-zralost.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/emocni-zralost.svg',
      color: '#F1B4AE'
    },
    testResults: [
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Snížená úroveň dovednosti',
          code: 1,
          textColor: '#000000',
          backgroundColor: '#FAB700'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      }
    ]
  },
  {
    category: {
      id: 8,
      uuid: '950b5973-04c5-4cc7-b11e-6f861919805c',
      name: 'Jemná motorika',
      activeIconUrl: '/media/uploaded/tags/active/category/motorika-jemna.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/motorika-jemna.svg',
      color: '#6CB7D8'
    },
    testResults: [
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:42:34.748000Z',
        evaluation: {
          title: 'Snížená úroveň dovednosti',
          code: 1,
          textColor: '#000000',
          backgroundColor: '#FAB700'
        }
      },
      {
        test: {
          id: 6,
          uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
          name: 'Pedagogická diagnostika 3-4',
          title: 'Pedagogická diagnostika 3-4',
          minAge: 1098,
          maxAge: 1464,
          age: 1200,
          etag: 'e4c2e8edac362acab7123654b9e73432',
          shortTitle: 'D 3-4',
          color: '#EF83A1',
          productCodename: 'test_3-4',
          version: '1.3'
        },
        finishedAt: '2022-08-16T20:44:06.929000Z',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          textColor: '#000000',
          backgroundColor: '#99BF28'
        }
      }
    ]
  }
];

export const multiTests: MultiResultTest[] = [
  {
    test: {
      id: 6,
      uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
      name: 'Pedagogická diagnostika 3-4',
      title: 'Pedagogická diagnostika 3-4',
      minAge: 1098,
      maxAge: 1464,
      age: 1200,
      etag: 'e4c2e8edac362acab7123654b9e73432',
      shortTitle: 'D 3-4',
      color: '#EF83A1',
      productCodename: 'test_3-4',
      version: '1.3'
    },
    finishedAt: '2022-08-16T20:42:34.748000Z'
  },
  {
    test: {
      id: 6,
      uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
      name: 'Pedagogická diagnostika 3-4',
      title: 'Pedagogická diagnostika 3-4',
      minAge: 1098,
      maxAge: 1464,
      age: 1200,
      etag: 'e4c2e8edac362acab7123654b9e73432',
      shortTitle: 'D 3-4',
      color: '#EF83A1',
      productCodename: 'test_3-4',
      version: '1.3'
    },
    finishedAt: '2022-08-16T20:44:06.929000Z'
  },
  {
    test: {
      id: 6,
      uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
      name: 'Pedagogická diagnostika 3-4',
      title: 'Pedagogická diagnostika 3-4',
      minAge: 1098,
      maxAge: 1464,
      age: 1200,
      etag: 'e4c2e8edac362acab7123654b9e73432',
      shortTitle: 'D 4-5',
      color: '#EF83A1',
      productCodename: 'test_4-5',
      version: '1.3'
    },
    finishedAt: '2022-08-16T20:42:34.748000Z'
  },
  {
    test: {
      id: 6,
      uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
      name: 'Pedagogická diagnostika 3-4',
      title: 'Pedagogická diagnostika 3-4',
      minAge: 1098,
      maxAge: 1464,
      age: 1200,
      etag: 'e4c2e8edac362acab7123654b9e73432',
      shortTitle: 'D 4-5',
      color: '#EF83A1',
      productCodename: 'test_4-5',
      version: '1.3'
    },
    finishedAt: '2022-08-16T20:44:06.929000Z'
  },
  {
    test: {
      id: 6,
      uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
      name: 'Pedagogická diagnostika 3-4',
      title: 'Pedagogická diagnostika 3-4',
      minAge: 1098,
      maxAge: 1464,
      age: 1200,
      etag: 'e4c2e8edac362acab7123654b9e73432',
      shortTitle: 'D 5-7',
      color: '#EF83A1',
      productCodename: 'test_5-7',
      version: '1.3'
    },
    finishedAt: '2022-08-16T20:42:34.748000Z'
  },
  {
    test: {
      id: 6,
      uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
      name: 'Pedagogická diagnostika 3-4',
      title: 'Pedagogická diagnostika 3-4',
      minAge: 1098,
      maxAge: 1464,
      age: 1200,
      etag: 'e4c2e8edac362acab7123654b9e73432',
      shortTitle: 'D 5-7',
      color: '#EF83A1',
      productCodename: 'test_5-7',
      version: '1.3'
    },
    finishedAt: '2022-08-16T20:44:06.929000Z'
  },
  {
    test: {
      id: 6,
      uuid: 'e016ee05-bf2e-46bf-8a09-bef1b2cc40fa',
      name: 'Pedagogická diagnostika 3-4',
      title: 'Pedagogická diagnostika 3-4',
      minAge: 1098,
      maxAge: 1464,
      age: 1200,
      etag: 'e4c2e8edac362acab7123654b9e73432',
      shortTitle: 'D 5-7',
      color: '#EF83A1',
      productCodename: 'test_5-7',
      version: '1.3'
    },
    finishedAt: '2022-08-17T20:44:06.929000Z'
  }
];
