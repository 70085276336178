<ng-container *transloco="let t">
  <app-report-wrapper *ngIf="data">
    <app-universal-header-report
      [reportName]="t('basicParent/reportName')"
      [subTitle]="t('qParent/subTitle')"
      [child]="data.child"
      [test]="data.test"
      [testData]="data.questionnaireData"
      [alignCenter]="true"
      [iconUrl]="'assets/img/logo/iSophiR_R.svg'"
      [qReport]="true"
      header
    ></app-universal-header-report>

    <tr class="row mt-3">
      <td class="col-6 left-info">
        <p class="fw-bold" [innerHTML]="t('qParent/textIntro')"></p>
        <p class="fst-italic" [innerHTML]="t('qParent/textNote')"></p>
      </td>
      <td class="col-6 right-info">
        <p [innerHTML]="t('qParent/textPreparing')"></p>
      </td>
    </tr>

    <tr class="row justify-content-center">
      <td>
        <app-section-header order="01" [text]="t('basicParent/header1')"></app-section-header>
      </td>
      <td class="col-6">
        <isophi-sun-graph-chart
          class="d-flex justify-content-center"
          [categoryResults]="reformatCategoryResults(data.questionnaireData.categoryResults, data.questionnaireData.lockedCategories)"
          [evaluations]="data.legend"
          [child]="data.child"
          [qReport]="true"
          [materialServer]="materialServer"
          [lock]="lock"
          [puppeteerApp]="true"
        ></isophi-sun-graph-chart>
      </td>
      <td class="col-3 d-flex align-items-end justify-content-center ms-3">
        <app-chart-hint [evaluations]="data.legend" [lock]="lock"></app-chart-hint>
      </td>
    </tr>

    <tr class="row">
      <td>
        <app-section-header order="02" [text]="t('basicParent/header2')"></app-section-header>
      </td>
      <ng-container *ngFor="let evaluation of sortLegend(data.legend); let index = index">
        <td class="col-6 emoji-container" [style]="{ '--emoji-color': evaluation.backgroundColor }">
          <div class="emoji">
            <img class="img-fluid" [src]="emojis[evaluation.code]" />
          </div>
          <div class="emoji-title">
            <strong>{{ evaluation.title }}</strong> {{ t('basicParent/abilityLevel') }}
          </div>
        </td>
        <td class="col-6">
          <p [innerHTML]="t(evaluationsText[index])"></p>
        </td>
      </ng-container>
    </tr>

    <tr class="row">
      <td class="col-7">
        <app-section-header order="03" [text]="t('basicParent/header3')"></app-section-header>

        <div
          class="support"
          *ngFor="
            let support of [
              ['assets/img/icons/house.png', t('basicParent/supportHead1'), t('basicParent/supportText1')],
              ['assets/img/icons/sun-card.png', t('basicParent/supportHead2'), t('basicParent/supportText2')],
              ['assets/img/icons/person-card.png', t('basicParent/supportHead3'), t('basicParent/supportText3')],
              ['assets/img/icons/book-card.png', t('basicParent/supportHead4'), t('basicParent/supportText4')],
              ['assets/img/icons/meeting.png', t('basicParent/supportHead5'), t('basicParent/supportText5')]
            ]
          "
        >
          <div>
            <img [src]="support[0]" alt="icon" />
          </div>
          <p>
            <span class="fw-bold">{{ support[1] }}</span
            ><br />
            {{ support[2] }}
          </p>
        </div>
      </td>
      <td class="col-5 g-0 card">
        <div class="card-body">
          <p class="card-text fw-bold mb-0">{{ t('qParent/whatNow') }}</p>
          <ul class="mb-0">
            <li
              *ngFor="
                let text of [
                  t('qParent/whatNowText1'),
                  t('qParent/whatNowText2'),
                  t('qParent/whatNowText3'),
                  t('qParent/whatNowText4'),
                  t('qParent/whatNowText5')
                ]
              "
            >
              {{ text }}
            </li>
          </ul>
        </div>
      </td>
    </tr>

    <tr class="row">
      <td class="col-12">
        <app-section-header order="04" [text]="t('qParent/header4')"></app-section-header>
      </td>
      <ng-container *ngFor="let eduActivity of reformatEduActivities(data.eduActivities, data.questionnaireData.lockedCategories)">
        <div class="col-12">
          <div class="activity-container row">
            <td class="col-7 mb-2">
              <div class="d-flex justify-content-between">
                <h5 class="d-flex align-items-center fw-bold">
                  <img class="img-fluid" [src]="materialServer + eduActivity.category.activeIconUrl" alt="icon" />{{
                    eduActivity.category.name
                  }}
                </h5>
                <img
                  class="img-fluid"
                  [src]="emojis[eduActivity.evaluation.code]"
                  alt="icon"
                  [style]="{ 'border-color': eduActivity.evaluation.backgroundColor }"
                />
              </div>
              <div [innerHTML]="eduActivity.explanation.description"></div>
            </td>
            <td class="col-5 card mb-2">
              <div class="card-body">
                <h5 class="fw-bold">{{ t('qParent/homeTips') }}</h5>
                <div class="ms-3" *ngFor="let tip of eduActivity.tips">
                  <p class="card-text fw-bold">{{ tip.title }}</p>
                  <p class="mb-2">{{ tip.description }}</p>
                </div>
                <h5 class="fw-bold">{{ t('qParent/findMore') }}</h5>
                <div class="ms-3">
                  <p class="mb-2">
                    <img class="img-fluid" src="assets/img/icons/person-card.png" alt="aktivit" />
                    {{ eduActivity.elseTipCount }} {{ t('qParent/activities') }}
                  </p>
                  <p class="mb-2">
                    <img class="img-fluid" src="assets/img/icons/sun-card.png" alt="aktivit" />
                    {{ eduActivity.worksheetCount }} {{ t('qParent/worksheets') }}
                  </p>
                  <p class="mb-2">
                    <img class="img-fluid" src="assets/img/icons/book-card.png" alt="aktivit" />
                    {{ eduActivity.videoCount }} {{ t('qParent/moreDetail') }}
                  </p>
                </div>
              </div>
            </td>
          </div>
        </div>
      </ng-container>
    </tr>
  </app-report-wrapper>
</ng-container>
