import { Component } from '@angular/core';
import { BaseReportComponent } from '../../core/components/base-report/base-report.component';
import { Category, ChildrenResult, Legend, OrderByEnum, Test } from 'isophi-pdfs-api';
import { IsophiData } from '../../../server/data.model';

import { orderByText } from './ordered-group-report.data';

const reportId = 'orderedGroup';
const documentId = '';

export interface OrderedGroupData extends IsophiData {
  reportId: typeof reportId;
  children: ChildrenResult[];
  test: Test;
  schoolYears: string[];
  orderBy: OrderByEnum;
  categories: Category[];
  legend: Legend[];
}

@Component({
  selector: 'app-ordered-group-report',
  templateUrl: './ordered-group-report.component.html',
  styleUrls: ['./ordered-group-report.component.scss'],
})
export class OrderedGroupReportComponent extends BaseReportComponent<OrderedGroupData> {
  get reportId(): string {
    return reportId;
  }

  get documentId(): string {
    return documentId;
  }

  public orderByText = orderByText;
}
