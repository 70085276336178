import { EduActivity, Questionnaire } from 'isophi-pdfs-api';

export const questionnaire: Questionnaire = {
  id: 0,
  uuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  name: "string",
  minAge: 0,
  maxAge: 0,
  productCodename: "string",
  version: 0,
  evaluationRules: [
    {
      id: 0,
      categoryUuid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      ranges: [
        {
          id: 0,
          code: 0,
          title: "string",
          imageUrl: "/media/uploaded/image.png",
          textColor: "string",
          backgroundColor: "string",
          minPercent: 0,
          maxPercent: 0,
          minPoint: 15,
          maxPoint: 20,
          shortTitle: "string"
        }
      ]
    }
  ]
}

export const questionnaireData = {
  id: 1,
  childUuid: 'edc1194d-8b1f-4eb8-b808-fdc39772cbee',
  questionnaireUuid: 'edc1184d-8b1f-4eb8-b808-fdc39772cbee',
  startedAt: '2022-05-13T16:17:39.952000+02:00',
  finishedAt: '2022-05-13T17:17:39.952000+02:00',
  uuid: 'edc1174d-8b1f-4eb8-b808-fdc39772cbee',
  categoryResults: [
    {
      category: {
        id: 1,
        uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
        name: 'Grafomotorika',
        activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
        color: '#E0937E'
      },
      maxScore: 21.0,
      score: 10.0,
      percent: 47.61905,
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 2,
        uuid: '491575d3-bede-4619-9d31-970701538e92',
        name: 'Předmatematické představy',
        activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
        color: '#F6687C'
      },
      maxScore: '18.0000000000',
      score: '11.0000000000',
      percent: '61.11111',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 3,
        uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
        name: 'Prostorová představivost',
        activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
        color: '#76CC7E'
      },
      maxScore: '14.0000000000',
      score: '6.0000000000',
      percent: '42.85714',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 4,
        uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
        name: 'Časová orientace',
        activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
        color: '#46C3C6'
      },
      maxScore: '10.0000000000',
      score: '7.0000000000',
      percent: '70.00000',
      evaluation: {
        title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
        code: 2,
        backgroundColor: '#99BF28',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 5,
        uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
        name: 'Zrakové vnímání',
        activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
        color: '#AADB6A'
      },
      maxScore: '10.0000000000',
      score: '6.0000000000',
      percent: '60.00000',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 18,
        uuid: 'bf388000-3822-48a4-ae7c-5662ea2fdd9b',
        name: 'Grafomotorika',
        activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
        color: '#E0937E'
      },
      maxScore: '21.0000000000',
      score: '10.0000000000',
      percent: '47.61905',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 6,
        uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
        name: 'Sluchové vnímání',
        activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
        color: '#F79061'
      },
      maxScore: '22.0000000000',
      score: '12.0000000000',
      percent: '54.54545',
      evaluation: {
        title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
        code: 2,
        backgroundColor: '#99BF28',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 7,
        uuid: 'b85c92c1-3f1b-49a2-80af-f92dd1ec6fc6',
        name: 'Verbální myšlení',
        activeIconUrl: '/media/uploaded/tags/active/category/rec.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/rec.svg',
        color: '#D39FBE'
      },
      maxScore: '14.0000000000',
      score: '9.0000000000',
      percent: '64.28571',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 8,
        uuid: '950b5973-04c5-4cc7-b11e-6f861919805c',
        name: 'Jemná motorika',
        activeIconUrl: '/media/uploaded/tags/active/category/motorika-jemna.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/motorika-jemna.svg',
        color: '#6CB7D8'
      },
      maxScore: '8.0000000000',
      score: '4.0000000000',
      percent: '50.00000',
      evaluation: {
        title: 'Nízká úroveň dovednosti',
        code: 0,
        backgroundColor: '#DA2C38',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 9,
        uuid: 'eedd61ec-80de-441c-8acf-9e6bd79d0bce',
        name: 'Hrubá motorika',
        activeIconUrl: '/media/uploaded/tags/active/category/motorika-hruba.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/motorika-hruba.svg',
        color: '#4585C4'
      },
      maxScore: '8.0000000000',
      score: '8.0000000000',
      percent: '100.00000',
      evaluation: {
        title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
        code: 2,
        backgroundColor: '#99BF28',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 11,
        uuid: '05a7f36a-2933-4cfb-90b7-8859d938cc98',
        name: 'Sociální porozumění',
        activeIconUrl: '/media/uploaded/tags/active/category/socialni-chovani.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/socialni-chovani.svg',
        color: '#B57878'
      },
      maxScore: '8.0000000000',
      score: '5.0000000000',
      percent: '62.50000',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 12,
        uuid: '20728a4c-0e11-429b-ad60-0e03b417597f',
        name: 'Sebeobsluha',
        activeIconUrl: '/media/uploaded/tags/active/category/samostatnost.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/samostatnost.svg',
        color: '#9972B2'
      },
      maxScore: '8.0000000000',
      score: '6.0000000000',
      percent: '75.00000',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 13,
        uuid: '28db1cd2-59ea-459c-a2c8-fba6638d3e96',
        name: 'Pracovní zralost',
        activeIconUrl: '/media/uploaded/tags/active/category/pracovni-zralost.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/pracovni-zralost.svg',
        color: '#F9D00B'
      },
      maxScore: '4.0000000000',
      score: '3.0000000000',
      percent: '75.00000',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000'
      }
    },
    {
      category: {
        id: 15,
        uuid: 'c3b91921-9a30-4765-92d2-d62d04deee5b',
        name: 'Emoční zralost',
        activeIconUrl: '/media/uploaded/tags/active/category/emocni-zralost.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/emocni-zralost.svg',
        color: '#F1B4AE'
      },
      maxScore: '5.0000000000',
      score: '3.0000000000',
      percent: '60.00000',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000'
      }
    }
  ],
  lockedCategories: [
    "a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa", "b85c92c1-3f1b-49a2-80af-f92dd1ec6fc6", "eedd61ec-80de-441c-8acf-9e6bd79d0bce", "05a7f36a-2933-4cfb-90b7-8859d938cc98", "20728a4c-0e11-429b-ad60-0e03b417597f", "28db1cd2-59ea-459c-a2c8-fba6638d3e96", "c3b91921-9a30-4765-92d2-d62d04deee5b"
  ]
};

export const eduActivities: EduActivity[] = [
  {
    category: {
      id: 4,
      uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
      name: 'Časová orientace',
      activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
      color: '#46C3C6'
    },
    evaluation: {
      title: 'Nízká úroveň dovednosti',
      code: 0,
      backgroundColor: '#DA2C38',
      textColor: '#000000'
    },
    tips: [
      {
        title: 'Kladení otázek',
        description:
          'Velmi užitečné jsou barevné dětské peníze, které mají na sobě nejen číslici, ale i počet teček. Dítě se učí struktuře teček, kterou začne později rozpoznávat rychleji i v jiných situacích a zároveň počet teček vidí ve vztahu k číslici.'
      },
      {
        title: 'Prostor',
        description:
          'Zkoumejte s dětmi velikost prostoru. Zeptejte se napříkjlad: „Kolik kroků měří místnost? Odhadni, kolik kroků musíš udělat ze svého pokoje do kuchyně?"'
      }
    ],
    explanation: {
      title: 'Vysvětlení oblasti Časová orientace',
      description:
        '<p>Časové vnímání je důležitá schopnost, která umožňuje pochopení souvislostí dějů a procesů. Vnímání času se utváří skrze vlastní zkušenosti a řeč, jak se o čase a událostech hovoří. Děti se s konceptem času sžívají několik let, než ho začnou samostatně používat ve svém životě. Vnímání času, příčiny a následku, se u dítěte rozvíjí postupně již od narození.</p><p>Předškolní dítě by mělo rozumět pojmům "včera, dnes, zítra, ráno, večer, den, noc" a přiměřeně je používat, dále vyjmenovat roční doby a k nim přiřadit hlavní znaky. Dítě v tomto věku se začíná orientovat ve dnech v týdnu, umí vyprávět krátkou pohádku od začátku do konce (např. s využitím obrázků).</p><p>Nedostatečné časové vnímání může mít za následek potíže v učení. Na pořadí písmen ve slově, slov ve větě či jednotlivých číslic v čísle, je zde přesná posloupnost, stejně jako v časových událostech. Mohou se objevovat potíže s dodržováním pořádku písmen ve slově, vynechávání písmen. Může být patrné namáhavé osvojování systematických informací – násobilka, abeceda, postupy v matematice, dny v týdnu či měsíce v roce. Dítě může mít sníženou schopnost při odhadování času na určité práce, s organizací činností a budování učebních strategií.</p>'
    },
    elseTipCount: 0,
    worksheetCount: 1,
    videoCount: 2
  },
  {
    category: {
      id: 4,
      uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
      name: 'Časová orientace',
      activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
      color: '#46C3C6'
    },
    evaluation: {
      title: 'Nízká úroveň dovednosti',
      code: 0,
      backgroundColor: '#DA2C38',
      textColor: '#000000'
    },
    tips: [
      {
        title: 'Kladení otázek',
        description:
          'Při osvojování správných tvarů písmen a číslic lze využít různá modelovací těsta či plastelínu.'
      },
      {
        title: 'Prostor',
        description:
          'Dejte dítěti nákres oblečení a poproste ho, ať dokreslí kroužky/proužky například panence na šaty'
      }
    ],
    explanation: {
      title: 'Vysvětlení oblasti Časová orientace',
      description:
        '<p>Časové vnímání je důležitá schopnost, která umožňuje pochopení souvislostí dějů a procesů. Vnímání času se utváří skrze vlastní zkušenosti a řeč, jak se o čase a událostech hovoří. Děti se s konceptem času sžívají několik let, než ho začnou samostatně používat ve svém životě. Vnímání času, příčiny a následku, se u dítěte rozvíjí postupně již od narození.</p><p>Předškolní dítě by mělo rozumět pojmům "včera, dnes, zítra, ráno, večer, den, noc" a přiměřeně je používat, dále vyjmenovat roční doby a k nim přiřadit hlavní znaky. Dítě v tomto věku se začíná orientovat ve dnech v týdnu, umí vyprávět krátkou pohádku od začátku do konce (např. s využitím obrázků).</p><p>Nedostatečné časové vnímání může mít za následek potíže v učení. Na pořadí písmen ve slově, slov ve větě či jednotlivých číslic v čísle, je zde přesná posloupnost, stejně jako v časových událostech. Mohou se objevovat potíže s dodržováním pořádku písmen ve slově, vynechávání písmen. Může být patrné namáhavé osvojování systematických informací – násobilka, abeceda, postupy v matematice, dny v týdnu či měsíce v roce. Dítě může mít sníženou schopnost při odhadování času na určité práce, s organizací činností a budování učebních strategií.</p>'
    },
    elseTipCount: 0,
    worksheetCount: 1,
    videoCount: 2
  },
  {
    category: {
      id: 4,
      uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
      name: 'Časová orientace',
      activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
      color: '#46C3C6'
    },
    evaluation: {
      title: 'Nízká úroveň dovednosti',
      code: 0,
      backgroundColor: '#DA2C38',
      textColor: '#000000'
    },
    tips: [
      {
        title: 'Kladení otázek',
        description:
          'Velmi užitečné jsou barevné dětské peníze, které mají na sobě nejen číslici, ale i počet teček. Dítě se učí struktuře teček, kterou začne později rozpoznávat rychleji i v jiných situacích a zároveň počet teček vidí ve vztahu k číslici.'
      },
      {
        title: 'Prostor',
        description:
          'Zkoumejte s dětmi velikost prostoru. Zeptejte se napříkjlad: „Kolik kroků měří místnost? Odhadni, kolik kroků musíš udělat ze svého pokoje do kuchyně?"'
      }
    ],
    explanation: {
      title: 'Vysvětlení oblasti Časová orientace',
      description:
        '<p>Časové vnímání je důležitá schopnost, která umožňuje pochopení souvislostí dějů a procesů. Vnímání času se utváří skrze vlastní zkušenosti a řeč, jak se o čase a událostech hovoří. Děti se s konceptem času sžívají několik let, než ho začnou samostatně používat ve svém životě. Vnímání času, příčiny a následku, se u dítěte rozvíjí postupně již od narození.</p><p>Předškolní dítě by mělo rozumět pojmům "včera, dnes, zítra, ráno, večer, den, noc" a přiměřeně je používat, dále vyjmenovat roční doby a k nim přiřadit hlavní znaky. Dítě v tomto věku se začíná orientovat ve dnech v týdnu, umí vyprávět krátkou pohádku od začátku do konce (např. s využitím obrázků).</p><p>Nedostatečné časové vnímání může mít za následek potíže v učení. Na pořadí písmen ve slově, slov ve větě či jednotlivých číslic v čísle, je zde přesná posloupnost, stejně jako v časových událostech. Mohou se objevovat potíže s dodržováním pořádku písmen ve slově, vynechávání písmen. Může být patrné namáhavé osvojování systematických informací – násobilka, abeceda, postupy v matematice, dny v týdnu či měsíce v roce. Dítě může mít sníženou schopnost při odhadování času na určité práce, s organizací činností a budování učebních strategií.</p>'
    },
    elseTipCount: 0,
    worksheetCount: 1,
    videoCount: 2
  },
  {
    category: {
      id: 4,
      uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
      name: 'Časová orientace',
      activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
      color: '#46C3C6'
    },
    evaluation: {
      title: 'Nízká úroveň dovednosti',
      code: 0,
      backgroundColor: '#DA2C38',
      textColor: '#000000'
    },
    tips: [
      {
        title: 'Kladení otázek',
        description:
          'Při osvojování správných tvarů písmen a číslic lze využít různá modelovací těsta či plastelínu.'
      },
      {
        title: 'Prostor',
        description:
          'Dejte dítěti nákres oblečení a poproste ho, ať dokreslí kroužky/proužky například panence na šaty'
      }
    ],
    explanation: {
      title: 'Vysvětlení oblasti Časová orientace',
      description:
        '<p>Časové vnímání je důležitá schopnost, která umožňuje pochopení souvislostí dějů a procesů. Vnímání času se utváří skrze vlastní zkušenosti a řeč, jak se o čase a událostech hovoří. Děti se s konceptem času sžívají několik let, než ho začnou samostatně používat ve svém životě. Vnímání času, příčiny a následku, se u dítěte rozvíjí postupně již od narození.</p><p>Předškolní dítě by mělo rozumět pojmům "včera, dnes, zítra, ráno, večer, den, noc" a přiměřeně je používat, dále vyjmenovat roční doby a k nim přiřadit hlavní znaky. Dítě v tomto věku se začíná orientovat ve dnech v týdnu, umí vyprávět krátkou pohádku od začátku do konce (např. s využitím obrázků).</p><p>Nedostatečné časové vnímání může mít za následek potíže v učení. Na pořadí písmen ve slově, slov ve větě či jednotlivých číslic v čísle, je zde přesná posloupnost, stejně jako v časových událostech. Mohou se objevovat potíže s dodržováním pořádku písmen ve slově, vynechávání písmen. Může být patrné namáhavé osvojování systematických informací – násobilka, abeceda, postupy v matematice, dny v týdnu či měsíce v roce. Dítě může mít sníženou schopnost při odhadování času na určité práce, s organizací činností a budování učebních strategií.</p>'
    },
    elseTipCount: 0,
    worksheetCount: 1,
    videoCount: 2
  }
];
