<ng-container *transloco="let t">
  <div class="d-flex flex-wrap justify-content-around problematic-categories-wrapper">
    <ng-container *ngFor="let problematicCategory of problematicCategories; let i = index">
      <div class="problematic-category">
        <div class="category-icon-wrapper">
          <div
            [ngStyle]="{
              width: '' + getIconSize(problematicCategory.children.length) + 'px',
              height: '' + getIconSize(problematicCategory.children.length) + 'px',
              'border-color': problematicCategory.category.color
            }"
            class="category-icon"
          >
            <img [src]="materialServer + problematicCategory.category.activeIconUrl" alt="icon" />
          </div>
        </div>
        <div class="category-name">
          <h2>{{ problematicCategory.category.name }}</h2>
        </div>
        <div class="problematic-children">
          <h3>
            <a>
              <strong>{{ t('shared/childrenWithDifficult') }} {{ problematicCategory.children.length }}</strong>
            </a>
          </h3>
        </div>
      </div>

      <div *ngIf="(i + 1) % 3 === 0 && i !== 0" class="w-100"></div>
    </ng-container>
  </div>
</ng-container>
