<ng-container *transloco="let t">
  <app-report-wrapper *ngIf="data">
    <app-universal-header-report
      [reportName]="t('gameScreening/reportName')"
      [subTitle]="t('gameScreening/subTitle')"
      [child]="data.child"
      [scenarioCategoryResults]="data.scenarioCategoryResults"
      [showGenderIcon]="true"
      [game]="true"
      [showIcon]="true"
      [iconUrl]="'assets/img/plane.png'"
      header
    ></app-universal-header-report>

    <app-chart-hint [evaluations]="data.legend"></app-chart-hint>

    <tr class="row position-relative results">
      <td class="col-12 mt-3">
        <app-section-header [text]="t('gameScreening/header')"></app-section-header>
      </td>
      <td class="col-12">
        <app-percentage class="d-flex justify-content-center"></app-percentage>

        <ng-container *ngFor="let categoryResult of data.scenarioCategoryResults.categoryResults; let index = index">
          <app-category-bar-chart
            [categoryResult]="categoryResult"
            [iconUrl]="materialServer + categoryResult.category.activeIconUrl"
            [showPoints]="false"
            [game]="true"
            class="d-block ms-3"
            [class]="{ 'mt-4': index > 0 }"
          ></app-category-bar-chart>

          <div class="task-results" *ngFor="let taskResult of categoryResult.taskResults">
            <div></div>
            <app-category-bar-chart [taskResult]="taskResult" [game]="true"></app-category-bar-chart>
          </div>
        </ng-container>
      </td>
    </tr>
  </app-report-wrapper>
</ng-container>
