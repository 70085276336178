import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReportListRouteComponent } from './core/components/report-list-route/report-list-route.component';
import { ProfileTokensComponent } from './document/profile-tokens/profile-tokens.component';
import { QrCodesComponent } from './document/qr-codes/qr-codes.component';
import { BasicGroupReportComponent } from './reports/basic-group-report/basic-group-report.component';
import { BasicParentReportComponent } from './reports/basic-parent-report/basic-parent-report.component';
import { BasicStemReportComponent } from './reports/basic-stem-report/basic-stem-report.component';
import { BasicTeacherReportComponent } from './reports/basic-teacher-report/basic-teacher-report.component';
import { ComparisonStemReportComponent } from './reports/comparison-stem-report/comparison-stem-report.component';
import { ComparisonTeacherReportComponent } from './reports/comparison-teacher-report/comparison-teacher-report.component';
import { DevelopmentMultiReportComponent } from './reports/development-multi-report/development-multi-report.component';
import { GameScreeningReportComponent } from './reports/game-screening-report/game-screening-report.component';
import { OrderedGroupReportComponent } from './reports/ordered-group-report/ordered-group-report.component';
import { QuestionnaireParentReportComponent } from './reports/questionnaire-parent-report/questionnaire-parent-report.component';

const routes: Routes = [
  { path: '', component: ReportListRouteComponent, pathMatch: 'full' },
  { path: 'basicParent', component: BasicParentReportComponent },
  { path: 'questionnaireParent', component: QuestionnaireParentReportComponent },
  { path: 'basicTeacher', component: BasicTeacherReportComponent },
  { path: 'comparisonTeacher', component: ComparisonTeacherReportComponent },
  { path: 'developmentMultireport', component: DevelopmentMultiReportComponent },
  { path: 'basicGroup', component: BasicGroupReportComponent },
  { path: 'orderedGroup', component: OrderedGroupReportComponent },
  { path: 'basicStem', component: BasicStemReportComponent },
  { path: 'comparisonStem', component: ComparisonStemReportComponent },
  { path: 'gameScreening', component: GameScreeningReportComponent },
  { path: 'qrCodes', component: QrCodesComponent },
  { path: 'profileTokens', component: ProfileTokensComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
