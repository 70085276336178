<ng-container *transloco="let t">
  <app-report-wrapper *ngIf="data">
    <app-universal-header-report
      [reportName]="t('basicStem/reportName')"
      [typeOfReport]="t('shared/reportComparison')"
      [comparison]="true"
      [subTitle]="t('comparisonStem/subTitle')"
      [child]="data.child"
      [test]="data.test"
      [testData]="data.testData[0]"
      [testData2]="data.testData[1]"
      [showGenderIcon]="true"
      [showIcon]="true"
      [iconUrl]="'assets/img/icon_stemcomparison.svg'"
      header
    ></app-universal-header-report>

    <div class="d-flex justify-content-end mt-3">
      <app-chart-hint [evaluations]="data.legend"></app-chart-hint>
    </div>

    <tr class="row chart-row g-0 ms-1">
      <td class="col-10 p-3">
        <app-section-header order="01" [text]="t('basicStem/header1')"></app-section-header>

        <div class="d-flex mb-1">
          <app-percentage></app-percentage>
          <div class="percent-compare">
            <p>{{ t('comparisonStem/shift') }}</p>
          </div>
        </div>
        <ng-container *ngFor="let categoryResult of data.testData[0].categoryResults.stemResults; let index = index">
          <app-category-bar-chart
            [categoryResult]="categoryResult"
            [categoryResult2]="data.testData[1].categoryResults.stemResults[index]"
            [iconUrl]="materialServer + categoryResult.category.activeIconUrl"
            [comparison]="true"
            [range]="getRanges(data.test.ranges, categoryResult)"
            class="d-block ms-3"
          ></app-category-bar-chart>
        </ng-container>
      </td>
      <td class="col-2" style="font-size: 12px">
        <div class="hint-container">
          <ng-container *ngFor="let i of [1, 2]; let index = index">
            <div class="comparison-hint" [ngClass]="{ opacity: index === 0 }">
              <span *ngFor="let evaluation of data.legend" [style]="{ 'background-color': evaluation.backgroundColor }"></span>
              {{ index + 1 }}. {{ t('shared/diagnostika') }}
            </div>
          </ng-container>
          <div class="mt-3">
            <img class="img-fluid icon" src="assets/img/arrow-up.jpg" alt="up" />
            {{ t('shared/increase') }}
          </div>
          <div class="mt-1">
            <img class="img-fluid icon" src="assets/img/arrow-down.png" alt="down" />
            {{ t('shared/decrease') }}
          </div>
          <div class="mt-1">
            <span class="icon ml-1 equal-color">=</span>
            {{ t('shared/decrease') }}
          </div>
        </div>
      </td>

      <td class="pie-chart col-10">
        <div class="d-flex flex-column justify-content-center">
          <ng-container *ngFor="let category of data.testData[0].categoryResults.stemResults">
            <app-hexagon class="mb-2" [iconUrl]="materialServer + category.category.inactiveIconUrl"></app-hexagon>
          </ng-container>
        </div>
        <div class="algorithmic-thinking">
          <div class="line" *ngFor="let category of data.testData[0].categoryResults.stemResults"></div>
          <app-hexagon [iconUrl]="materialServer + data.testData[0].categoryResults.computedResult.category.inactiveIconUrl"></app-hexagon>
          <p class="fw-bold">
            {{ data.testData[0].categoryResults.computedResult.category.name }}
          </p>
        </div>
        <isophi-category-doughnut-chart
          class="d-block"
          [categoryResult]="data.testData[0].categoryResults.computedResult"
          [categoryResult2]="data.testData[1].categoryResults.computedResult"
          [comparison]="true"
          [puppeteerApp]="true"
        ></isophi-category-doughnut-chart>
      </td>
    </tr>

    <tr class="row">
      <td>
        <app-section-header class="d-block mt-1 ms-4" order="02" [text]="t('basicStem/header2')"></app-section-header>
      </td>
      <td class="col-10">
        <ng-container *ngFor="let categoryResult of data.testData[0].categoryResults.otherResults; let index = index">
          <app-category-bar-chart
            [categoryResult]="categoryResult"
            [categoryResult2]="data.testData[1].categoryResults.otherResults[index]"
            [iconUrl]="materialServer + categoryResult.category.activeIconUrl"
            [comparison]="true"
            [range]="getRanges(data.test.ranges, categoryResult)"
            class="d-block"
            style="margin-left: 2.7rem"
          ></app-category-bar-chart>
        </ng-container>
      </td>
    </tr>
  </app-report-wrapper>
</ng-container>
