import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-notes-comparison-wraper',
  templateUrl: './notes-comparison-wraper.component.html',
  styleUrls: ['./notes-comparison-wraper.component.scss']
})
export class NotesComparisonWraperComponent {
  @Input() public title: string;
}
