import { TokenChild } from 'isophi-pdfs-api';

export const childrenProfileTokensData: TokenChild[] = [
  {
    uuid: 'edc1194d-8b1f-4eb8-b808-fdc39772cbee',
    gender: 'male',
    lastName: 'Péťa',
    firstName: 'A.',
    birthDate: '2015-6-1',
    revision: 0,
    token: 'string',
    id: 1
  },
  {
    uuid: 'edc1184d-8b1f-4eb8-b808-fdc39772cbee',
    gender: 'male',
    lastName: 'Péťa',
    firstName: 'A.',
    birthDate: '2015-6-1',
    revision: 0,
    token: 'string',
    id: 2
  },
  {
    uuid: 'edc1174d-8b1f-4eb8-b808-fdc39772cbee',
    gender: 'male',
    lastName: 'Péťa',
    firstName: 'test1',
    birthDate: '2015-6-1',
    revision: 0,
    token: 'string',
    id: 3
  },
  {
    uuid: 'edc1164d-8b1f-4eb8-b808-fdc39772cbee',
    gender: 'male',
    lastName: 'Péťa',
    firstName: 'test',
    birthDate: '2015-6-1',
    revision: 0,
    token: 'string',
    id: 4
  },
  {
    uuid: 'edc1174d-8b1f-4eb8-b808-fdc39772cbee',
    gender: 'male',
    lastName: 'Péťa',
    firstName: 'test1',
    birthDate: '2015-6-1',
    revision: 0,
    token: 'string',
    id: 5
  },
  {
    uuid: 'edc1164d-8b1f-4eb8-b808-fdc39772cbee',
    gender: 'male',
    lastName: 'Péťa',
    firstName: 'test',
    birthDate: '2015-6-1',
    revision: 0,
    token: 'string',
    id: 6
  }
];
