import { Component } from '@angular/core';

/**
 * Reusable component for percentage.
 */
@Component({
  selector: 'app-percentage',
  templateUrl: './percentage.component.html',
  styleUrls: ['./percentage.component.scss']
})
export class PercentageComponent {}
