import { Component, Input } from '@angular/core';
import { breakPoint, checkNotesQuestions, questionTypeEnum, sectionTypeEunm } from '../../../core/utils/notes.utils';
import { Section } from 'isophi-pdfs-api';

@Component({
  selector: 'app-note-content',
  templateUrl: './note-content.component.html',
  styleUrls: ['./note-content.component.scss'],
})
export class NoteContentComponent {
  @Input() public note: Section;

  @Input() public notes?: Section[];

  @Input() public comparison = false;

  public sectionTypeEunm = sectionTypeEunm;

  public questionTypeEnum = questionTypeEnum;

  public checkNotesQuestions = checkNotesQuestions;

  public breakPoint = breakPoint;

  replacedNote(value: string): string {
    return `${this.replaceNewlinesWithBreaks(value)}`;
  }

  private replaceNewlinesWithBreaks(text: string = ''): string {
    return text.replace(/\n/g, '<br>');
  }
}
