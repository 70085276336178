import { Component } from '@angular/core';
import { BaseReportComponent } from '../../core/components/base-report/base-report.component';
import { getRanges } from '../../core/utils/ranges.utils';
import { Child, Legend, StemReportTestData, Test } from 'isophi-pdfs-api';
import { IsophiData } from '../../../../src/server/data.model';

const reportId = 'comparisonStem';
const documentId = '';

export interface ComparisonStemData extends IsophiData {
  reportId: typeof reportId;
  child: Child;
  test: Test;
  testData: StemReportTestData[];
  legend: Legend[];
}

@Component({
  selector: 'app-comparison-stem-report',
  templateUrl: './comparison-stem-report.component.html',
  styleUrls: ['./comparison-stem-report.component.scss'],
})
export class ComparisonStemReportComponent extends BaseReportComponent<ComparisonStemData> {
  get reportId(): string {
    return reportId;
  }

  get documentId(): string {
    return documentId;
  }

  public getRanges = getRanges;
}
