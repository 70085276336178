/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { CategoryGameResult, CategoryResult, TaskResult } from 'isophi-pdfs-api';

/**
 * Reusable component for category bar chart such as a cirial chart and cirial comparison.
 */
@Component({
  selector: 'app-category-bar-chart',
  templateUrl: './category-bar-chart.component.html',
  styleUrls: ['./category-bar-chart.component.scss']
})
export class CategoryBarChartComponent {
  @Input() public categoryResult: CategoryResult | CategoryGameResult;

  @Input() public categoryResult2?: CategoryResult;

  @Input() public taskResult?: TaskResult;

  @Input() public comparison?: boolean = false;

  @Input() public iconUrl: string;

  @Input() public range?: any;

  @Input() public showPoints = true;

  @Input() public game? = false;

  public score = '+0%';

  public math = Math;

  public calcDistance(index: number): string {
    const factor = 100 / +this.categoryResult.maxScore;
    const distance = Math.round(index * factor);
    return `${distance}%`;
  }

  public calcBorderOpacity(index: number, percent: number, maxScore: number): string {
    const indexPercent = (index / maxScore) * 100;

    if (Math.round(indexPercent) < Math.round(percent)) {
      return '0.6';
    }
    return '1';
  }

  public comparisonScore(score1: number, score2: number): string {
    let score = 0;

    if (score1 > score2) {
      score = Math.round(score1 - score2);
      this.score = `-${score}%`;
      return `assets/img/arrow-down.png`;
    } else if (score1 < score2) {
      score = Math.round(score2 - score1);
      this.score = `+${score}%`;
      return `assets/img/arrow-up.jpg`;
    } else {
      score = 0;
      this.score = `+${score}%`;
    }
  }

  public scorePercent(percent: number): number {
    return Math.round(percent);
  }
}
