export const testData = {
  id: 9,
  uuid: 'c8e2d7bf-38f1-4187-8fea-109a9f3fa9f2',
  name: '',
  title: 'Pedagogická diagnostika školní zralosti v2.0',
  minAge: 5,
  maxAge: 9,
  age: 6,
  etag: '',
  color: '#B2CB07',
  shortTitle: 'D 5-7',
  productCodename: '',
  version: '',
  ranges: {
    id: 1,
    evaluationType: '',
    testRule: null,
    categoryRules: [
      {
        id: 1,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
      },
      {
        id: 2,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: '491575d3-bede-4619-9d31-970701538e92',
      },
      {
        id: 3,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
      },
      {
        id: 4,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
      },
      {
        id: 5,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
      },
      {
        id: 6,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: 'bf388000-3822-48a4-ae7c-5662ea2fdd9b',
      },
      {
        id: 7,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
      },
      {
        id: 8,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: 'b85c92c1-3f1b-49a2-80af-f92dd1ec6fc6',
      },
      {
        id: 9,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: '950b5973-04c5-4cc7-b11e-6f861919805c',
      },
      {
        id: 10,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: 'eedd61ec-80de-441c-8acf-9e6bd79d0bce',
      },
      {
        id: 11,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: '05a7f36a-2933-4cfb-90b7-8859d938cc98',
      },
      {
        id: 12,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: '20728a4c-0e11-429b-ad60-0e03b417597f',
      },
      {
        id: 13,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: '28db1cd2-59ea-459c-a2c8-fba6638d3e96',
      },
      {
        id: 14,
        ranges: [
          {
            id: 163,
            code: 0,
            title: 'Nízká úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 164,
            code: 1,
            title: 'Snížená úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
          },
          {
            id: 165,
            code: 2,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
          },
        ],
        categoryId: 1,
        categoryUuid: 'c3b91921-9a30-4765-92d2-d62d04deee5b',
      },
    ],
    generalRule: {
      id: 8,
      ranges: [
        {
          id: 160,
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          textColor: '#000000',
          backgroundColor: '#99BF28',
          minPoint: null,
          maxPoint: null,
          minPercent: 67,
          maxPercent: 100,
        },
        {
          id: 161,
          code: 1,
          title: 'Snížená úroveň dovednosti',
          textColor: '#000000',
          backgroundColor: '#FAB700',
          minPoint: null,
          maxPoint: null,
          minPercent: 34,
          maxPercent: 66,
        },
        {
          id: 162,
          code: 0,
          title: 'Nízká úroveň dovednosti',
          textColor: '#000000',
          backgroundColor: '#DA2C38',
          minPoint: null,
          maxPoint: null,
          minPercent: 0,
          maxPercent: 33,
        },
      ],
    },
  },
};

export const testDataData = {
  id: 1,
  uuid: 'edc1194d-8b1f-4eb8-b808-fdc39783cbee',
  childUuid: 'edc1194d-8b1f-4eb8-b808-fdc39772cbee',
  testId: 9,
  testUuid: 'c8e2d7bf-38f1-4187-8fea-109a9f3fa9f2',
  status: 'false',
  startedAt: '2022-05-13T16:17:39.952000+02:00',
  finishedAt: '2022-09-15T17:17:39.952000+02:00',
  notes: [
    {
      title: 'Poznámky ke grafomotorickým úkolům',
      sectionType: 'question',
      subSections: [
        {
          title: 'Držení tužky',
          questions: [
            {
              title: 'správně',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'propnutý ukazovák',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'prostředníček (eventuálně i prsteník) shora',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'palec příliš vepředu',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'palec překřížený přes prsty',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'držení příliš dole',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'držení příliš nahoře',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'nevyužívá opory o podložku',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'držení dvěma prsty. Kterými?',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'držení v dlani palcem nahoru',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'držení v dlani palcem dolů',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'jiné',
              visibleTitle: true,
              questionType: 'text',
              value: 'hello there',
            },
          ],
        },
        {
          title: 'Plynulost tahů',
          questions: [
            {
              title: 'plynulé v přiměřeném tempu',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'plynulé v pomalejším tempu',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 's občasnými zárazy',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'časté zárazy',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'plynulé, ale příliš rychlé, odbyté',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'zbrklé, příliš rychlé, neplynulé',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
          ],
        },
        {
          title: 'Přítlak',
          questions: [
            {
              title: 'Přítlak',
              visibleTitle: false,
              questionType: 'option',
              options: [
                {
                  id: 11,
                  title: 'příliš slabý',
                  imageUrl: 'tests/notes/options/pressure-too-weak.png',
                  order: 11,
                },
                {
                  id: 12,
                  title: 'slabý',
                  imageUrl: 'tests/notes/options/pressure-weak.png',
                  order: 12,
                },
                {
                  id: 13,
                  title: 'přiměřený',
                  imageUrl: 'tests/notes/options/pressure-ok.png',
                  order: 13,
                },
                {
                  id: 14,
                  title: 'silný',
                  imageUrl: 'tests/notes/options/pressure-strong.png',
                  order: 14,
                },
                {
                  id: 15,
                  title: 'příliš silný',
                  imageUrl: 'tests/notes/options/pressure-too-strong.png',
                  order: 15,
                },
              ],
              value: '13',
            },
          ],
        },
      ],
    },
    {
      title: 'Poznámky k pozornosti',
      sectionType: 'question',
      subSections: [
        {
          title: 'Pozornost dítěte během diagnostiky',
          questions: [
            {
              title: 'koncentruje se po celou dobu spolupráce',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'drobné odklony pozornosti',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'potřebuje delší zácvik a zopakování pokynů u každého úkolu',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'pracuje pouze pod přímým vedením s nutností motivace k dokončení úkolu',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'známky rychlého nástupu psychické únavy, psychomotorický neklid',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'pozornost mělká, snadno vyrušitelná i drobnými okolními podněty',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
          ],
        },
      ],
    },
    {
      title: 'Poznámky k řeči',
      sectionType: 'question',
      subSections: [
        {
          title: 'Které hlásky ještě nejsou navozeny?',
          questions: [
            {
              title: 'R Ř L',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'C Z S',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'Č Š Ž',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'záměny sykavek',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'K',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'měkčení (dě/tě/ně, di/ti, ni)',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'jiné:',
              visibleTitle: true,
              questionType: 'text',
              value: 'A i jiné',
            },
          ],
        },
      ],
    },
    {
      title: 'Váš komentář rodiči pro rodičovský report',
      sectionType: 'text',
      value: 'Toto je komentář pro rodiče, učiteli by se ve finále neměl zobrazit na učitelském reportu.',
      // textInfo: 'Pokud je komentář vyplněn, bude natisknut na rodičovský report.',
      // placeholder: 'Váš komentář, který uvidí rodič...',
      // maxTextLen: 350
    },
    {
      title: 'Vaše interní poznámky',
      sectionType: 'text',
      value: '#4 Pozornost je zaměření psychiky na určité vybrané podněty. U dětí se nejdříve rozvíjí tzv. bezděčná pozornost související s vrozenými orientačními reflexy. Časem je dítě schopno zaměřovat pozornost na určité předměty. Postupně se u dítěte objevuje pozornost záměrná, která umožňuje rozvoj vlastní aktivity, kterou dělá dítě s nějakým cílem. Dítě před 5. rokem se nevydrží příliš dlouho soustředit na jeden jev. Hledá nové podněty, rozptýlí ho maličkost či aktuální nálada či pocit. Proto je nutné činnosti s dětmi střídat a měnit. Děti stále potřebují i poměrně hodně odpočinku. V předškolním věku již většinou děti dokáží v klidu sedět, když druhý něco vysvětluje, přiměřenou chvilku poslouchat a neříkat vše, co je napadne. Dokáží také dokončit zadaný úkol, ačkoli nepatří k těm oblíbeným. Dovedou již na přiměřenou dobu (asi 10 min) ovlivnit záměrnou pozornost. V případě potíží s koncentrací pozornosti se děti dokáží soustředit pouze na oblasti, které je zajímají.',
    }
  ],
  categoryResults: [
    {
      category: {
        id: 1,
        uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
        name: 'Grafomotorika',
        activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
        color: '#E0937E',
      },
      maxScore: 21.0,
      score: 10.0,
      percent: 47.61905,
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 2,
        uuid: '491575d3-bede-4619-9d31-970701538e92',
        name: 'Předmatematické představy',
        activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
        color: '#F6687C',
      },
      maxScore: '18.0000000000',
      score: '11.0000000000',
      percent: '61.11111',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 3,
        uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
        name: 'Prostorová představivost',
        activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
        color: '#76CC7E',
      },
      maxScore: '14.0000000000',
      score: '6.0000000000',
      percent: '42.85714',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 4,
        uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
        name: 'Časová orientace',
        activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
        color: '#46C3C6',
      },
      maxScore: '10.0000000000',
      score: '7.0000000000',
      percent: '70.00000',
      evaluation: {
        title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
        code: 2,
        backgroundColor: '#99BF28',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 5,
        uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
        name: 'Zrakové vnímání',
        activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
        color: '#AADB6A',
      },
      maxScore: '10.0000000000',
      score: '6.0000000000',
      percent: '60.00000',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 18,
        uuid: 'bf388000-3822-48a4-ae7c-5662ea2fdd9b',
        name: 'Grafomotorika',
        activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
        color: '#E0937E',
      },
      maxScore: '21.0000000000',
      score: '10.0000000000',
      percent: '47.61905',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 6,
        uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
        name: 'Sluchové vnímání',
        activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
        color: '#F79061',
      },
      maxScore: '22.0000000000',
      score: '12.0000000000',
      percent: '54.54545',
      evaluation: {
        title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
        code: 2,
        backgroundColor: '#99BF28',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 7,
        uuid: 'b85c92c1-3f1b-49a2-80af-f92dd1ec6fc6',
        name: 'Verbální myšlení',
        activeIconUrl: '/media/uploaded/tags/active/category/rec.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/rec.svg',
        color: '#D39FBE',
      },
      maxScore: '14.0000000000',
      score: '9.0000000000',
      percent: '64.28571',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 8,
        uuid: '950b5973-04c5-4cc7-b11e-6f861919805c',
        name: 'Jemná motorika',
        activeIconUrl: '/media/uploaded/tags/active/category/motorika-jemna.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/motorika-jemna.svg',
        color: '#6CB7D8',
      },
      maxScore: '8.0000000000',
      score: '4.0000000000',
      percent: '50.00000',
      evaluation: {
        title: 'Nízká úroveň dovednosti',
        code: 0,
        backgroundColor: '#DA2C38',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 9,
        uuid: 'eedd61ec-80de-441c-8acf-9e6bd79d0bce',
        name: 'Hrubá motorika',
        activeIconUrl: '/media/uploaded/tags/active/category/motorika-hruba.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/motorika-hruba.svg',
        color: '#4585C4',
      },
      maxScore: '8.0000000000',
      score: '8.0000000000',
      percent: '100.00000',
      evaluation: {
        title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
        code: 2,
        backgroundColor: '#99BF28',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 11,
        uuid: '05a7f36a-2933-4cfb-90b7-8859d938cc98',
        name: 'Sociální porozumění',
        activeIconUrl: '/media/uploaded/tags/active/category/socialni-chovani.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/socialni-chovani.svg',
        color: '#B57878',
      },
      maxScore: '8.0000000000',
      score: '5.0000000000',
      percent: '62.50000',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 12,
        uuid: '20728a4c-0e11-429b-ad60-0e03b417597f',
        name: 'Sebeobsluha',
        activeIconUrl: '/media/uploaded/tags/active/category/samostatnost.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/samostatnost.svg',
        color: '#9972B2',
      },
      maxScore: '8.0000000000',
      score: '6.0000000000',
      percent: '75.00000',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 13,
        uuid: '28db1cd2-59ea-459c-a2c8-fba6638d3e96',
        name: 'Pracovní zralost',
        activeIconUrl: '/media/uploaded/tags/active/category/pracovni-zralost.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/pracovni-zralost.svg',
        color: '#F9D00B',
      },
      maxScore: '4.0000000000',
      score: '3.0000000000',
      percent: '75.00000',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000',
      },
    },
    {
      category: {
        id: 15,
        uuid: 'c3b91921-9a30-4765-92d2-d62d04deee5b',
        name: 'Emoční zralost',
        activeIconUrl: '/media/uploaded/tags/active/category/emocni-zralost.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/emocni-zralost.svg',
        color: '#F1B4AE',
      },
      maxScore: '5.0000000000',
      score: '3.0000000000',
      percent: '60.00000',
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000',
      },
    },
  ],
};

export const testStemData = {
  id: 571,
  childUuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
  testId: 8,
  testUuid: 'c8e2d7bf-38f1-4187-8fea-109a9f3fa9f2',
  status: 'completed',
  startedAt: '2022-08-16T09:01:05.911000+02:00',
  finishedAt: '2022-08-16T09:05:15.879000+02:00',
  uuid: 'e21b7e9d-afc7-4e59-8277-d79bb078e7cb',
  notes: [
    {
      title: 'Poznámky ke grafomotorickým úkolům',
      sectionType: 'question',
      subSections: [
        {
          title: 'Držení tužky',
          questions: [
            {
              title: 'správně',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'propnutý ukazovák',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'prostředníček (eventuálně i prsteník) shora',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'palec příliš vepředu',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'palec překřížený přes prsty',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'držení příliš dole',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'držení příliš nahoře',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'nevyužívá opory o podložku',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'držení dvěma prsty. Kterými?',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'držení v dlani palcem nahoru',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'držení v dlani palcem dolů',
              visibleTitle: true,
              questionType: 'bool',
              value: 'true',
            },
            {
              title: 'jiné',
              visibleTitle: true,
              questionType: 'text',
              value: 'test',
            },
          ],
        },
        {
          title: 'Plynulost tahů',
          questions: [
            {
              title: 'plynulé v přiměřeném tempu',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'plynulé v pomalejším tempu',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 's občasnými zárazy',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'časté zárazy',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'plynulé, ale příliš rychlé, odbyté',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'zbrklé, příliš rychlé, neplynulé',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
          ],
        },
        {
          title: 'Přítlak',
          questions: [
            {
              title: 'Přítlak',
              visibleTitle: false,
              questionType: 'option',
              options: [
                {
                  id: 11,
                  title: 'příliš slabý',
                  imageUrl: 'tests/notes/options/pressure-too-weak.png',
                  order: 11,
                },
                {
                  id: 12,
                  title: 'slabý',
                  imageUrl: 'tests/notes/options/pressure-weak.png',
                  order: 12,
                },
                {
                  id: 13,
                  title: 'přiměřený',
                  imageUrl: 'tests/notes/options/pressure-ok.png',
                  order: 13,
                },
                {
                  id: 14,
                  title: 'silný',
                  imageUrl: 'tests/notes/options/pressure-strong.png',
                  order: 14,
                },
                {
                  id: 15,
                  title: 'příliš silný',
                  imageUrl: 'tests/notes/options/pressure-too-strong.png',
                  order: 15,
                },
              ],
              value: '12',
            },
          ],
        },
      ],
    },
    {
      title: 'Poznámky k pozornosti',
      sectionType: 'question',
      subSections: [
        {
          title: 'Pozornost dítěte během diagnostiky',
          questions: [
            {
              title: 'koncentruje se po celou dobu spolupráce',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'drobné odklony pozornosti',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'potřebuje delší zácvik a zopakování pokynů u každého úkolu',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'pracuje pouze pod přímým vedením s nutností motivace k dokončení úkolu',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'známky rychlého nástupu psychické únavy, psychomotorický neklid',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'pozornost mělká, snadno vyrušitelná i drobnými okolními podněty',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
          ],
        },
      ],
    },
    {
      title: 'Poznámky k řeči',
      sectionType: 'question',
      subSections: [
        {
          title: 'Které hlásky ještě nejsou navozeny?',
          questions: [
            {
              title: 'R Ř L',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'C Z S',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'Č Š Ž',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'záměny sykavek',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'K',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'měkčení (dě/tě/ně, di/ti, ni)',
              visibleTitle: true,
              questionType: 'bool',
              value: null,
            },
            {
              title: 'jiné:',
              visibleTitle: true,
              questionType: 'text',
              value: null,
            },
          ],
        },
      ],
    },
    {
      title: 'Váš komentář rodiči pro rodičovský report',
      sectionType: 'text',
      value: null,
      // textInfo: 'Pokud je komentář vyplněn, bude natisknut na rodičovský report.',
      // placeholder: 'Váš komentář, který uvidí rodič...',
      // maxTextLen: 350
    },
  ],
  categoryResults: {
    stemResults: [
      {
        category: {
          id: 2,
          uuid: '491575d3-bede-4619-9d31-970701538e92',
          name: 'Předmatematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C',
        },
        maxScore: 18,
        score: 18,
        percent: 100,
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E',
        },
        maxScore: '14.0000000000',
        score: '14.0000000000',
        percent: '100.00000',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 4,
          uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
          name: 'Časová orientace',
          activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
          color: '#46C3C6',
        },
        maxScore: '10.0000000000',
        score: '10.0000000000',
        percent: '100.00000',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A',
        },
        maxScore: '10.0000000000',
        score: '10.0000000000',
        percent: '100.00000',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
    ],
    computedResult: {
      category: {
        id: 14,
        uuid: 'cbb212e7-0168-4c05-a38c-db9768610d1e',
        name: 'Informatické myšlení',
        activeIconUrl: '/media/uploaded/tags/active/category/algoritmy.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/algoritmy.svg',
        color: '#FFF18F',
      },
      maxScore: 52,
      score: 30,
      percent: 57.69230769,
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#FAB700',
        textColor: '#000000',
      },
    },
    otherResults: [
      {
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E',
        },
        maxScore: 21,
        score: 21,
        percent: 100,
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061',
        },
        maxScore: '22.0000000000',
        score: '22.0000000000',
        percent: '100.00000',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 7,
          uuid: 'b85c92c1-3f1b-49a2-80af-f92dd1ec6fc6',
          name: 'Verbální myšlení',
          activeIconUrl: '/media/uploaded/tags/active/category/rec.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/rec.svg',
          color: '#D39FBE',
        },
        maxScore: '14.0000000000',
        score: '14.0000000000',
        percent: '100.00000',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 8,
          uuid: '950b5973-04c5-4cc7-b11e-6f861919805c',
          name: 'Jemná motorika',
          activeIconUrl: '/media/uploaded/tags/active/category/motorika-jemna.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/motorika-jemna.svg',
          color: '#6CB7D8',
        },
        maxScore: '8.0000000000',
        score: '8.0000000000',
        percent: '100.00000',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 9,
          uuid: 'eedd61ec-80de-441c-8acf-9e6bd79d0bce',
          name: 'Hrubá motorika',
          activeIconUrl: '/media/uploaded/tags/active/category/motorika-hruba.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/motorika-hruba.svg',
          color: '#4585C4',
        },
        maxScore: '8.0000000000',
        score: '8.0000000000',
        percent: '100.00000',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 11,
          uuid: '05a7f36a-2933-4cfb-90b7-8859d938cc98',
          name: 'Sociální porozumění',
          activeIconUrl: '/media/uploaded/tags/active/category/socialni-chovani.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/socialni-chovani.svg',
          color: '#B57878',
        },
        maxScore: '8.0000000000',
        score: '8.0000000000',
        percent: '100.00000',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 12,
          uuid: '20728a4c-0e11-429b-ad60-0e03b417597f',
          name: 'Sebeobsluha',
          activeIconUrl: '/media/uploaded/tags/active/category/samostatnost.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/samostatnost.svg',
          color: '#9972B2',
        },
        maxScore: '8.0000000000',
        score: '8.0000000000',
        percent: '100.00000',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 13,
          uuid: '28db1cd2-59ea-459c-a2c8-fba6638d3e96',
          name: 'Pracovní zralost',
          activeIconUrl: '/media/uploaded/tags/active/category/pracovni-zralost.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/pracovni-zralost.svg',
          color: '#F9D00B',
        },
        maxScore: '4.0000000000',
        score: '4.0000000000',
        percent: '100.00000',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 15,
          uuid: 'c3b91921-9a30-4765-92d2-d62d04deee5b',
          name: 'Emoční zralost',
          activeIconUrl: '/media/uploaded/tags/active/category/emocni-zralost.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/emocni-zralost.svg',
          color: '#F1B4AE',
        },
        maxScore: '5.0000000000',
        score: '5.0000000000',
        percent: '100.00000',
        evaluation: {
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          code: 2,
          backgroundColor: '#99BF28',
          textColor: '#000000',
        },
      },
    ],
  },
};

export const blueData = {
  testData: {
    id: 575,
    childUuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
    testId: 1,
    testUuid: '132a0635-699a-4438-bf71-40ae1bc8c023',
    status: 'completed',
    startedAt: '2022-08-16T21:37:30.152000+02:00',
    finishedAt: '2022-08-16T21:39:25.256000+02:00',
    uuid: '0d936fc0-2675-4a93-8ec9-ade2255cfe40',
    notes: [
      {
        title: 'Poznámky ke grafomotorickým úkolům',
        sectionType: 'question',
        subSections: [
          {
            title: 'Držení tužky',
            questions: [
              {
                title: 'správně',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'propnutý ukazovák',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'prostředníček (event. i prsteník) shora',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'palec příliš vpředu',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'palec překřížený přes prsty',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'držení příliš dole',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'držení příliš nahoře',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'nevyužívá opory o podložku',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'držení dvěma prsty. Kterými?',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'držení v dlani palcem nahoru',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'držení v dlani palcem dolů',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'jiné:',
                visibleTitle: true,
                questionType: 'text',
                value: '',
              },
            ],
          },
          {
            title: 'plynulost tahů',
            questions: [
              {
                title: 'plynulé v přiměřeném tempu',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'plynulé v pomaleším tempu',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 's občasnými zárazy',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'časté zárazy',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'plynulé, ale příliš rychlé, odbyté',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'zbrklé, příliš rychlé, neplynulé',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
            ],
          },
          {
            title: 'Přítlak',
            questions: [
              {
                title: 'přítlak',
                visibleTitle: false,
                questionType: 'option',
                options: [
                  {
                    id: 1,
                    title: 'příliš slabý',
                    imageUrl: 'tests/notes/options/pressure-too-weak.png',
                  },
                  {
                    id: 2,
                    title: 'slabší',
                    imageUrl: 'tests/notes/options/pressure-weak.png',
                  },
                  {
                    id: 3,
                    title: 'přiměřený',
                    imageUrl: 'tests/notes/options/pressure-ok.png',
                  },
                  {
                    id: 4,
                    title: 'silnější',
                    imageUrl: 'tests/notes/options/pressure-strong.png',
                  },
                  {
                    id: 5,
                    title: 'příliš silný',
                    imageUrl: 'tests/notes/options/pressure-too-strong.png',
                  },
                ],
                value: '3',
              },
            ],
          },
        ],
      },
      {
        title: 'Poznámky k pozornosti',
        sectionType: 'text',
        value: null,
        textInfo: null,
        placeholder: null,
        maxTextTen: null,
      },
      {
        title: 'Poznámky k řeči',
        sectionType: 'question',
        subSections: [
          {
            title: 'Které hlásky ještě nejsou navozeny?',
            questions: [
              {
                title: 'R Ř L',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'C S Z',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'Č Š Ž',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'záměny sykavek',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'K',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'měkčení (dě/tě/ně, di/ti/ni)',
                visibleTitle: true,
                questionType: 'bool',
                value: null,
              },
              {
                title: 'jiné:',
                visibleTitle: true,
                questionType: 'text',
                value: null,
              },
            ],
          },
        ],
      },
    ],
    categoryResults: [
      {
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E',
        },
        maxScore: 21.0,
        score: 21.0,
        percent: 100.0,
        evaluation: {
          title: 'Nadprůměr',
          code: 3,
          backgroundColor: '#56BECC',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 2,
          uuid: '491575d3-bede-4619-9d31-970701538e92',
          name: 'Předmatematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C',
        },
        maxScore: 18.0,
        score: 18.0,
        percent: 100.0,
        evaluation: {
          title: 'Nadprůměr',
          code: 3,
          backgroundColor: '#56BECC',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E',
        },
        maxScore: 13.0,
        score: 13.0,
        percent: 100.0,
        evaluation: {
          title: 'Nadprůměr',
          code: 3,
          backgroundColor: '#56BECC',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 4,
          uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
          name: 'Časová orientace',
          activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
          color: '#46C3C6',
        },
        maxScore: 10.0,
        score: 10.0,
        percent: 100.0,
        evaluation: {
          title: 'Nadprůměr',
          code: 3,
          backgroundColor: '#56BECC',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A',
        },
        maxScore: 10.0,
        score: 10.0,
        percent: 100.0,
        evaluation: {
          title: 'Nadprůměr',
          code: 3,
          backgroundColor: '#56BECC',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061',
        },
        maxScore: 24.0,
        score: 24.0,
        percent: 100.0,
        evaluation: {
          title: 'Nadprůměr',
          code: 3,
          backgroundColor: '#56BECC',
          textColor: '#000000',
        },
      },
      {
        category: {
          id: 7,
          uuid: 'b85c92c1-3f1b-49a2-80af-f92dd1ec6fc6',
          name: 'Verbální myšlení',
          activeIconUrl: '/media/uploaded/tags/active/category/rec.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/rec.svg',
          color: '#D39FBE',
        },
        maxScore: 14.0,
        score: 14.0,
        percent: 100.0,
        evaluation: {
          title: 'Nadprůměr',
          code: 3,
          backgroundColor: '#56BECC',
          textColor: '#000000',
        },
      },
    ],
  },
  legend: [
    {
      code: 0,
      title: 'Nízká',
      backgroundColor: '#f25a4a',
      textColor: '#000000',
    },
    {
      code: 1,
      title: 'Snížená',
      backgroundColor: '#fac022',
      textColor: '#000000',
    },
    {
      code: 2,
      title: 'Přiměřená',
      backgroundColor: '#c5e154',
      textColor: '#000000',
    },
    {
      code: 3,
      title: 'Velmi dobrá',
      backgroundColor: '#56becc',
      textColor: '#000000',
    },
  ],
};
