import { Component, Input, OnInit } from '@angular/core';
import { ChildAgeFormat, childFullName, roundAge } from '@isophi/core-ui';
import {
  Child,
  GroupReportInfo,
  MultiResultTest,
  QuestionnaireData,
  ScenarioCategoryResult,
  StemReportTestData,
  Test,
  TestData
} from 'isophi-pdfs-api';

@Component({
  selector: 'app-universal-header-report',
  templateUrl: './universal-header-report.component.html',
  styleUrls: ['./universal-header-report.component.scss'],
})
export class UniversalHeaderReportComponent implements OnInit {
  @Input() public child: Child;

  @Input() public groupReportInfo: GroupReportInfo;

  @Input() public test?: Test;

  @Input() public testData?: TestData | StemReportTestData | QuestionnaireData;

  @Input() public testData2?: TestData | StemReportTestData;

  @Input() public comparison = false;

  @Input() public multiReport = false;

  @Input() public tests: MultiResultTest[];

  @Input() public reportName = 'STEM';

  @Input() public typeOfReport?: string;

  @Input() public showGenderIcon = false;

  @Input() public subTitle = 'Lorem ipsum dolor sit amet consectetur';

  @Input() public alignCenter = false;

  @Input() public polar = false;

  @Input() public game = false;

  @Input() public scenarioCategoryResults?: ScenarioCategoryResult;

  @Input() public orderdGroup = false;

  @Input() public numberOfChildren?: number;

  @Input() public schoolYears?: string[];

  @Input() public group = false;

  @Input() public qReport = false;

  @Input() public showIcon = false;

  @Input() public iconUrl = '';

  public childAgeFormat: ChildAgeFormat = ChildAgeFormat.YEAR_MONTH_FORMAT;

  public childFullName = childFullName;

  public roundAge = roundAge;

  public ngOnInit(): void {
    this.reportName = this.reportName.toLocaleUpperCase();
  }

  public getChildIconSrc(child: Child): string {
    const gender = child.gender === 'female' ? 'girl' : 'boy';
    return `assets/img/iconsDefault/${gender}.svg`;
  }

  public testTypes(tests: MultiResultTest[]): string[] {
    const testTypes = tests.map((val) => val.test.shortTitle);
    return [...new Set(testTypes)];
  }

  public filterTests(tests: MultiResultTest[], type: string): MultiResultTest[] {
    return tests.filter((val) => val.test.shortTitle === type);
  }

  public count(tests: MultiResultTest[], test: MultiResultTest): number {
    return tests.findIndex((val) => val.finishedAt === test.finishedAt && val.test.shortTitle === test.test.shortTitle) + 1;
  }
}
