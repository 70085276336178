<ng-container *transloco="let t">
  <app-report-wrapper *ngIf="data">
    <app-universal-header-report
      [reportName]="t('multiReport/reportName')"
      [subTitle]="t('multiReport/subTitle')"
      [child]="data.child"
      [tests]="data.tests.slice(0, 7)"
      [multiReport]="true"
      [showGenderIcon]="true"
      [showIcon]="true"
      [iconUrl]="'assets/img/icon_multireport.svg'"
      header
    ></app-universal-header-report>

    <tr class="row g-0 row-container">
      <td>
        <app-section-header [text]="t('multiReport/header')"></app-section-header>
      </td>
      <td class="col-3"></td>
      <td class="col-1 d-flex flex-column align-items-center" *ngFor="let test of data.tests.slice(0, 7); let index = index">
        <span class="number-font">{{ index + 1 }}.</span>
        <isophi-simple-badge
          [title]="test.test.shortTitle"
          [style]="{ '--bg-color': '#000', '--font-size': '14px' }"
          [ngStyle]="
            test.test.shortTitle === 'D 3-4'
              ? { opacity: '0.4' }
              : test.test.shortTitle === 'D 4-5'
              ? { opacity: '0.6' }
              : { opacity: '0.8' }
          "
        >
        </isophi-simple-badge>
        <div class="date-format">{{ test.finishedAt | date: 'M/YYYY' }}</div>
      </td>
      <td class="mt-3" [class]="textCol(data.tests.length)">
        <p class="line-info">{{ t('multiReport/diagnostikType') }}</p>
        <p class="line-info">{{ t('multiReport/diagnostikDate') }}</p>
      </td>
      <td class="col-3"></td>
      <td [class]="arrowCol(data.tests.slice(0, 7).length)">
        <hr class="arrow" />
      </td>
    </tr>

    <tr class="row g-0 mt-2 row-container">
      <ng-container *ngFor="let childResult of data.childResults; let lastElem = last">
        <td class="col-12 child-result d-flex align-items-center">
          <div class="row g-0 w-100">
            <div class="col-3 d-flex align-items-center">
              <app-hexagon [iconUrl]="materialServer + childResult.category.inactiveIconUrl"></app-hexagon>
              <p class="mb-0">{{ childResult.category.name }}</p>
            </div>
            <ng-container *ngFor="let test of data.tests.slice(0, 7); let i = index; let first = first; let last = last">
              <div
                class="col-1 d-flex justify-content-center align-items-center"
                [class]="{ 'verticle-border': test.test.shortTitle !== data.tests.slice(0, 7)[i + 1]?.test.shortTitle && !last }"
              >
                <div
                  *ngIf="checkTestResult(test, childResult.testResults); else noTest"
                  class="test-badge"
                  [style]="{ '--test-color': checkTestResult(test, childResult.testResults).evaluation.backgroundColor }"
                ></div>
                <ng-template #noTest>
                  <div class="text-secondary fw-bold">X</div>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </td>
        <td *ngIf="!lastElem" class="col-12 empty-result d-flex align-items-center">
          <div class="row g-0 w-100">
            <div class="col-3 d-flex align-items-center"></div>
            <ng-container *ngFor="let test of data.tests.slice(0, 7); let i = index; let first = first; let last = last">
              <div
                class="col-1 d-flex justify-content-center align-items-center"
                [class]="{ 'verticle-border': test.test.shortTitle !== data.tests.slice(0, 7)[i + 1]?.test.shortTitle && !last }"
              >
                <div></div>
              </div>
            </ng-container>
          </div>
        </td>
      </ng-container>
    </tr>

    <tr class="row row-container">
      <td class="col-3"></td>
      <td class="col-9">
        <app-chart-hint [evaluations]="data.legend"></app-chart-hint>
      </td>
    </tr>
  </app-report-wrapper>
</ng-container>
