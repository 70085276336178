import { CategoryResult, GroupCategoryResult, GroupReportInfo, Legend, Test } from 'isophi-pdfs-api';

export const groupReportInfo: GroupReportInfo = {
  groups: [
    {
      id: 1,
      uuid: 'c8e2d5bf-38f1-4187-8fea-109a9f3fa9f2',
      name: 'I_test',
      revision: 0,
    },
    {
      id: 2,
      uuid: 'c8e2d4bf-38f1-4187-8fea-109a9f3fa9f2',
      name: 'I_test_2',
      revision: 0,
    },
    {
      id: 3,
      uuid: 'c8e2d5bf-38f1-4187-8fea-109a9f3fa9f2',
      name: 'Berušky',
      revision: 0,
    },
    {
      id: 4,
      uuid: 'c8e2d4bf-38f1-4187-8fea-109a9f3fa9f2',
      name: 'Broučci',
      revision: 0,
    },
    {
      id: 5,
      uuid: 'c8e2d5bf-38f1-4187-8fea-109a9f3fa9f2',
      name: 'BUG_GROUP_REPORT',
      revision: 0,
    },
    {
      id: 6,
      uuid: 'c8e2d4bf-38f1-4187-8fea-109a9f3fa9f2',
      name: 'Flow',
      revision: 0,
    },
    {
      id: 7,
      uuid: 'c8e2d5bf-38f1-4187-8fea-109a9f3fa9f2',
      name: 'Motýlci',
      revision: 0,
    },
    {
      id: 8,
      uuid: 'c8e2d4bf-38f1-4187-8fea-109a9f3fa9f2',
      name: 'NikdoNeni',
      revision: 0,
    },
    {
      id: 9,
      uuid: 'c8e2d4bf-38f1-4187-8fea-109a9f3fa9f2',
      name: 'Veverky',
      revision: 0,
    },
    {
      id: 10,
      uuid: 'c8e2d4bf-38f1-4187-8fea-109a9f3fa9f2',
      name: 'ZS Hrebec',
      revision: 0,
    },
    {
      id: 11,
      uuid: 'c8e2d4bf-38f1-4187-8fea-109a9f3fa9f2',
      name: 'Žirafy',
      revision: 0,
    }
  ],
  children: [
    {
      uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
      firstName: 'T',
      lastName: 'K',
      birthDate: '2016-03-10',
      gender: 'male',
      revision: 2,
      age: 5.4548,
      resultAge: 5.45,
    },
    {
      uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
      firstName: 'Chuma',
      lastName: 'Tue',
      birthDate: '2016-03-10',
      gender: 'male',
      revision: 0,
      age: 7.1254,
      resultAge: 7.12,
    },
  ],
  dateFrom: '2022-01-05',
  dateTo: '2022-08-31',
  childrenCount: 2,
  averageAge: 6.75687,
};

export const groupCategoryResults: CategoryResult[] = [
  {
    percent: 100,
    score: 14.0,
    maxScore: 14.0,
    category: {
      id: 7,
      uuid: 'b85c92c1-3f1b-49a2-80af-f92dd1ec6fc6',
      name: 'Verbální myšlení',
      activeIconUrl: '/media/uploaded/tags/active/category/rec.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/rec.svg',
      color: '#D39FBE',
    },
    evaluation: {
      title: 'Nízká úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#DA2C38',
      code: 0,
    },
  },
  {
    percent: 50,
    score: 4,
    maxScore: 8.0,
    category: {
      id: 8,
      uuid: '950b5973-04c5-4cc7-b11e-6f861919805c',
      name: 'Jemná motorika',
      activeIconUrl: '/media/uploaded/tags/active/category/motorika-jemna.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/motorika-jemna.svg',
      color: '#6CB7D8',
    },
    evaluation: {
      title: 'Nízká úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#DA2C38',
      code: 0,
    },
  },
  {
    percent: 0,
    score: 0,
    maxScore: 8.0,
    category: {
      id: 9,
      uuid: 'eedd61ec-80de-441c-8acf-9e6bd79d0bce',
      name: 'Hrubá motorika',
      activeIconUrl: '/media/uploaded/tags/active/category/motorika-hruba.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/motorika-hruba.svg',
      color: '#4585C4',
    },
    evaluation: {
      title: 'Nízká úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#DA2C38',
      code: 0,
    },
  },
  {
    percent: 0,
    score: 0,
    maxScore: 8.0,
    category: {
      id: 11,
      uuid: '05a7f36a-2933-4cfb-90b7-8859d938cc98',
      name: 'Sociální porozumění',
      activeIconUrl: '/media/uploaded/tags/active/category/socialni-chovani.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/socialni-chovani.svg',
      color: '#B57878',
    },
    evaluation: {
      title: 'Snížená úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#FAB700',
      code: 1,
    },
  },
  {
    percent: 0,
    score: 0,
    maxScore: 8.0,
    category: {
      id: 12,
      uuid: '20728a4c-0e11-429b-ad60-0e03b417597f',
      name: 'Sebeobsluha',
      activeIconUrl: '/media/uploaded/tags/active/category/samostatnost.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/samostatnost.svg',
      color: '#9972B2',
    },
    evaluation: {
      title: 'Nízká úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#DA2C38',
      code: 0,
    },
  },
  {
    percent: 0,
    score: 0,
    maxScore: 5.0,
    category: {
      id: 15,
      uuid: 'c3b91921-9a30-4765-92d2-d62d04deee5b',
      name: 'Emoční zralost',
      activeIconUrl: '/media/uploaded/tags/active/category/emocni-zralost.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/emocni-zralost.svg',
      color: '#F1B4AE',
    },
    evaluation: {
      title: 'Nízká úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#DA2C38',
      code: 0,
    },
  },
  {
    percent: 0,
    score: 0,
    maxScore: 21.0,
    category: {
      id: 1,
      uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
      name: 'Grafomotorika',
      activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
      color: '#E0937E',
    },
    evaluation: {
      title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#99BF28',
      code: 2,
    },
  },
  {
    percent: 0,
    score: 0,
    maxScore: 18.0,
    category: {
      id: 2,
      uuid: '491575d3-bede-4619-9d31-970701538e92',
      name: 'Předmatematické představy',
      activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
      color: '#F6687C',
    },
    evaluation: {
      title: 'Snížená úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#FAB700',
      code: 1,
    },
  },
  {
    percent: 0,
    score: 0,
    maxScore: 14.0,
    category: {
      id: 3,
      uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
      name: 'Prostorová představivost',
      activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
      color: '#76CC7E',
    },
    evaluation: {
      title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#99BF28',
      code: 2,
    },
  },
  {
    percent: 0,
    score: 0,
    maxScore: 10.0,
    category: {
      id: 4,
      uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
      name: 'Časová orientace',
      activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
      color: '#46C3C6',
    },
    evaluation: {
      title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#99BF28',
      code: 2,
    },
  },
  {
    percent: 0,
    score: 0,
    maxScore: 10.0,
    category: {
      id: 5,
      uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
      name: 'Zrakové vnímání',
      activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
      color: '#AADB6A',
    },
    evaluation: {
      title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#99BF28',
      code: 2,
    },
  },
  {
    percent: 0,
    score: 0,
    maxScore: 4.0,
    category: {
      id: 13,
      uuid: '28db1cd2-59ea-459c-a2c8-fba6638d3e96',
      name: 'Pracovní zralost',
      activeIconUrl: '/media/uploaded/tags/active/category/pracovni-zralost.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/pracovni-zralost.svg',
      color: '#F9D00B',
    },
    evaluation: {
      title: 'Snížená úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#FAB700',
      code: 1,
    },
  },
];

export const groupProblematicCategories: GroupCategoryResult[] = [
  {
    percent: 100,
    score: 14.0,
    maxScore: 14.0,
    category: {
      id: 7,
      uuid: 'b85c92c1-3f1b-49a2-80af-f92dd1ec6fc6',
      name: 'Verbální myšlení',
      activeIconUrl: '/media/uploaded/tags/active/category/rec.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/rec.svg',
      color: '#D39FBE',
    },
    evaluation: {
      id: 182,
      title: 'Nízká úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#DA2C38',
      minPoint: 0,
      maxPoint: 6,
      minPercent: null,
      maxPercent: null,
      code: 0,
    },
    childrenCount: 18,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
  {
    percent: 50,
    score: 4,
    maxScore: 8.0,
    category: {
      id: 8,
      uuid: '950b5973-04c5-4cc7-b11e-6f861919805c',
      name: 'Jemná motorika',
      activeIconUrl: '/media/uploaded/tags/active/category/motorika-jemna.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/motorika-jemna.svg',
      color: '#6CB7D8',
    },
    evaluation: {
      id: 199,
      title: 'Nízká úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#DA2C38',
      minPoint: 0,
      maxPoint: 4,
      minPercent: null,
      maxPercent: null,
      code: 0,
    },
    childrenCount: 36,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
  {
    percent: 0,
    score: 0,
    maxScore: 8.0,
    category: {
      id: 9,
      uuid: 'eedd61ec-80de-441c-8acf-9e6bd79d0bce',
      name: 'Hrubá motorika',
      activeIconUrl: '/media/uploaded/tags/active/category/motorika-hruba.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/motorika-hruba.svg',
      color: '#4585C4',
    },
    evaluation: {
      id: 202,
      title: 'Nízká úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#DA2C38',
      minPoint: 0,
      maxPoint: 5,
      minPercent: null,
      maxPercent: null,
      code: 0,
    },
    childrenCount: 18,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
  {
    percent: 0,
    score: 0,
    maxScore: 8.0,
    category: {
      id: 11,
      uuid: '05a7f36a-2933-4cfb-90b7-8859d938cc98',
      name: 'Sociální porozumění',
      activeIconUrl: '/media/uploaded/tags/active/category/socialni-chovani.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/socialni-chovani.svg',
      color: '#B57878',
    },
    evaluation: {
      id: 187,
      title: 'Snížená úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#FAB700',
      minPoint: 3,
      maxPoint: 5,
      minPercent: null,
      maxPercent: null,
      code: 1,
    },
    childrenCount: 2,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
  {
    percent: 0,
    score: 0,
    maxScore: 8.0,
    category: {
      id: 12,
      uuid: '20728a4c-0e11-429b-ad60-0e03b417597f',
      name: 'Sebeobsluha',
      activeIconUrl: '/media/uploaded/tags/active/category/samostatnost.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/samostatnost.svg',
      color: '#9972B2',
    },
    evaluation: {
      id: 196,
      title: 'Nízká úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#DA2C38',
      minPoint: 0,
      maxPoint: 4,
      minPercent: null,
      maxPercent: null,
      code: 0,
    },
    childrenCount: 18,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
  {
    percent: 0,
    score: 0,
    maxScore: 5.0,
    category: {
      id: 15,
      uuid: 'c3b91921-9a30-4765-92d2-d62d04deee5b',
      name: 'Emoční zralost',
      activeIconUrl: '/media/uploaded/tags/active/category/emocni-zralost.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/emocni-zralost.svg',
      color: '#F1B4AE',
    },
    evaluation: {
      id: 189,
      title: 'Nízká úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#DA2C38',
      minPoint: 0,
      maxPoint: 2,
      minPercent: null,
      maxPercent: null,
      code: 0,
    },
    childrenCount: 2,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
  {
    percent: 0,
    score: 0,
    maxScore: 21.0,
    category: {
      id: 1,
      uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
      name: 'Grafomotorika',
      activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
      color: '#E0937E',
    },
    evaluation: {
      id: 165,
      title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#99BF28',
      minPoint: 12,
      maxPoint: 21,
      minPercent: null,
      maxPercent: null,
      code: 2,
    },
    childrenCount: 1,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
  {
    percent: 0,
    score: 0,
    maxScore: 18.0,
    category: {
      id: 2,
      uuid: '491575d3-bede-4619-9d31-970701538e92',
      name: 'Předmatematické představy',
      activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
      color: '#F6687C',
    },
    evaluation: {
      id: 167,
      title: 'Snížená úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#FAB700',
      minPoint: 10,
      maxPoint: 13,
      minPercent: null,
      maxPercent: null,
      code: 1,
    },
    childrenCount: 18,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
  {
    percent: 0,
    score: 0,
    maxScore: 14.0,
    category: {
      id: 3,
      uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
      name: 'Prostorová představivost',
      activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
      color: '#76CC7E',
    },
    evaluation: {
      id: 171,
      title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#99BF28',
      minPoint: 8,
      maxPoint: 14,
      minPercent: null,
      maxPercent: null,
      code: 2,
    },
    childrenCount: 18,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
  {
    percent: 0,
    score: 0,
    maxScore: 10.0,
    category: {
      id: 4,
      uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
      name: 'Časová orientace',
      activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
      color: '#46C3C6',
    },
    evaluation: {
      id: 174,
      title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#99BF28',
      minPoint: 5,
      maxPoint: 10,
      minPercent: null,
      maxPercent: null,
      code: 2,
    },
    childrenCount: 18,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
  {
    percent: 0,
    score: 0,
    maxScore: 10.0,
    category: {
      id: 5,
      uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
      name: 'Zrakové vnímání',
      activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
      color: '#AADB6A',
    },
    evaluation: {
      id: 178,
      title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#99BF28',
      minPoint: 7,
      maxPoint: 10,
      minPercent: null,
      maxPercent: null,
      code: 2,
    },
    childrenCount: 18,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
  {
    percent: 0,
    score: 0,
    maxScore: 4.0,
    category: {
      id: 13,
      uuid: '28db1cd2-59ea-459c-a2c8-fba6638d3e96',
      name: 'Pracovní zralost',
      activeIconUrl: '/media/uploaded/tags/active/category/pracovni-zralost.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/pracovni-zralost.svg',
      color: '#F9D00B',
    },
    evaluation: {
      id: 193,
      title: 'Snížená úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#FAB700',
      minPoint: 2,
      maxPoint: 3,
      minPercent: null,
      maxPercent: null,
      code: 1,
    },
    childrenCount: 18,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
  {
    percent: 0,
    score: 0,
    maxScore: 4.0,
    category: {
      id: 13,
      uuid: '28db1cd2-59ea-459c-a2c8-fba6638d3e96',
      name: 'Pracovní zralost',
      activeIconUrl: '/media/uploaded/tags/active/category/pracovni-zralost.svg',
      inactiveIconUrl: '/media/uploaded/tags/inactive/category/pracovni-zralost.svg',
      color: '#F9D00B',
    },
    evaluation: {
      id: 193,
      title: 'Snížená úroveň dovednosti',
      textColor: '#000000',
      backgroundColor: '#FAB700',
      minPoint: 2,
      maxPoint: 3,
      minPercent: null,
      maxPercent: null,
      code: 1,
    },
    childrenCount: 18,
    children: [
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
      {
        uuid: '8a3f6fc3-9c2f-45f3-a592-c7f47b60338c',
        firstName: 'T',
        lastName: 'K',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 2,
      },
      {
        uuid: '7936d18a-c2e3-4625-ac10-81ce3a19adfa',
        firstName: 'Chuma',
        lastName: 'Tue',
        birthDate: '2016-03-10',
        gender: 'male',
        revision: 0,
      },
    ],
  },
];

export const groupTest: Test = {
  id: 8,
  uuid: 'c8e2d7bf-38f1-4187-8fea-109a9f3fa9f2',
  name: 'Pedagogická diagnostika školní zralosti v2.0',
  title: 'Pedagogická diagnostika školní zralosti v2.0',
  minAge: 1850,
  maxAge: 2600,
  age: 1826,
  etag: 'e4c2e8edac362acab7123654b9e73432',
  shortTitle: 'D 5-7',
  color: '#B2CB07',
  productCodename: 'test_5-7_v2',
  version: '1.3',
  ranges: {
    id: 6,
    evaluationType: 'basic',
    testRule: null,
    categoryRules: [
      {
        id: 50,
        categoryId: 1,
        categoryUuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
        ranges: [
          {
            id: 163,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 164,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 165,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 12,
            maxPoint: 21,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
      {
        id: 51,
        categoryId: 2,
        categoryUuid: '491575d3-bede-4619-9d31-970701538e92',
        ranges: [
          {
            id: 166,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 9,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 167,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 10,
            maxPoint: 13,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 168,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 14,
            maxPoint: 18,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
      {
        id: 52,
        categoryId: 3,
        categoryUuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
        ranges: [
          {
            id: 169,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 4,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 170,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 5,
            maxPoint: 7,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 171,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 8,
            maxPoint: 14,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
      {
        id: 53,
        categoryId: 4,
        categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
        ranges: [
          {
            id: 172,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 2,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 173,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 3,
            maxPoint: 4,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 174,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 5,
            maxPoint: 10,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
      {
        id: 54,
        categoryId: 5,
        categoryUuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
        ranges: [
          {
            id: 176,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 4,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 177,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 5,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 178,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 7,
            maxPoint: 10,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
      {
        id: 55,
        categoryId: 6,
        categoryUuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
        ranges: [
          {
            id: 179,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 180,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 10,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 181,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 11,
            maxPoint: 22,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
      {
        id: 56,
        categoryId: 7,
        categoryUuid: 'b85c92c1-3f1b-49a2-80af-f92dd1ec6fc6',
        ranges: [
          {
            id: 182,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 183,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 7,
            maxPoint: 9,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 184,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 10,
            maxPoint: 14,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
      {
        id: 57,
        categoryId: 11,
        categoryUuid: '05a7f36a-2933-4cfb-90b7-8859d938cc98',
        ranges: [
          {
            id: 186,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 2,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 187,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 3,
            maxPoint: 5,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 188,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 6,
            maxPoint: 8,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
      {
        id: 58,
        categoryId: 15,
        categoryUuid: 'c3b91921-9a30-4765-92d2-d62d04deee5b',
        ranges: [
          {
            id: 189,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 2,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 190,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 3,
            maxPoint: 3,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 191,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 4,
            maxPoint: 5,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
      {
        id: 59,
        categoryId: 13,
        categoryUuid: '28db1cd2-59ea-459c-a2c8-fba6638d3e96',
        ranges: [
          {
            id: 192,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 1,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 193,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 2,
            maxPoint: 3,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 194,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 4,
            maxPoint: 4,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
      {
        id: 60,
        categoryId: 12,
        categoryUuid: '20728a4c-0e11-429b-ad60-0e03b417597f',
        ranges: [
          {
            id: 196,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 4,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 197,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 5,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 198,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 7,
            maxPoint: 8,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
      {
        id: 61,
        categoryId: 8,
        categoryUuid: '950b5973-04c5-4cc7-b11e-6f861919805c',
        ranges: [
          {
            id: 199,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 4,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 200,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 5,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 201,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 7,
            maxPoint: 8,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
      {
        id: 62,
        categoryId: 9,
        categoryUuid: 'eedd61ec-80de-441c-8acf-9e6bd79d0bce',
        ranges: [
          {
            id: 202,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 5,
            minPercent: null,
            maxPercent: null,
            code: 0,
          },
          {
            id: 203,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 6,
            maxPoint: 7,
            minPercent: null,
            maxPercent: null,
            code: 1,
          },
          {
            id: 204,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 8,
            maxPoint: 8,
            minPercent: null,
            maxPercent: null,
            code: 2,
          },
        ],
      },
    ],
    generalRule: {
      id: 8,
      ranges: [
        {
          id: 160,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          shortTitle: null,
          textColor: '#000000',
          backgroundColor: '#99BF28',
          minPoint: null,
          maxPoint: null,
          minPercent: 67,
          maxPercent: 100,
          code: 2,
        },
        {
          id: 161,
          title: 'Snížená úroveň dovednosti',
          shortTitle: null,
          textColor: '#000000',
          backgroundColor: '#FAB700',
          minPoint: null,
          maxPoint: null,
          minPercent: 34,
          maxPercent: 66,
          code: 1,
        },
        {
          id: 162,
          title: 'Nízká úroveň dovednosti',
          shortTitle: null,
          textColor: '#000000',
          backgroundColor: '#DA2C38',
          minPoint: null,
          maxPoint: null,
          minPercent: 0,
          maxPercent: 33,
          code: 0,
        },
      ],
    },
  },
};

export const groupLegend: Legend[] = [
  {
    code: 0,
    title: 'Nízká',
    backgroundColor: '#f25a4a',
    textColor: '#000000',
  },
  {
    code: 1,
    title: 'Snížená',
    backgroundColor: '#fac022',
    textColor: '#000000',
  },
  {
    code: 2,
    title: 'Přiměřená nebo velmi dobrá',
    backgroundColor: '#c5e154',
    textColor: '#000000',
  },
];
