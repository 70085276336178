import { Component } from '@angular/core';
import { BaseReportComponent } from '../../core/components/base-report/base-report.component';
import { getRanges } from '../../core/utils/ranges.utils';
import { Child, Legend, Test, TestData } from 'isophi-pdfs-api';
import { IsophiData } from '../../../server/data.model';
import { sectionTypeEunm } from '../../core/utils/notes.utils';

const reportId = 'basicTeacher';
const documentId = '';

export interface BasicTeacherData extends IsophiData {
  reportId: typeof reportId;
  child: Child;
  test: Test;
  testData: TestData;
  legend: Legend[];
}

@Component({
  selector: 'app-basic-teacher-report',
  templateUrl: './basic-teacher-report.component.html',
  styleUrls: ['./basic-teacher-report.component.scss'],
})
export class BasicTeacherReportComponent extends BaseReportComponent<BasicTeacherData> {
  public getRanges = getRanges;

  public sectionTypeEunm = sectionTypeEunm;

  get reportId(): string {
    return reportId;
  }

  get documentId(): string {
    return documentId;
  }
}
