const evaluationsData = [
  {
    code: 2,
    title: 'Přiměřená nebo velmi dobrá',
    backgroundColor: '#99BF28',
    textColor: '#000000'
  },
  {
    code: 1,
    title: 'Snížená',
    backgroundColor: '#FAB700',
    textColor: '#000000'
  },
  {
    code: 0,
    title: 'Nízká',
    backgroundColor: '#DA2C38',
    textColor: '#000000'
  }
];

export default evaluationsData;
