<ng-container *transloco="let t">
  <app-report-wrapper *ngIf="data">
    <app-universal-header-report
      [reportName]="t('basicParent/reportName')"
      [subTitle]="t('basicParent/subTitle')"
      [child]="data.child"
      [test]="data.test"
      [testData]="data.testData"
      [alignCenter]="true"
      [iconUrl]="'assets/img/icon_parent.svg'"
      header
    ></app-universal-header-report>

    <tr class="row mt-3" *ngIf="data.test.shortTitle === 'Z'; else test45">
      <td class="col-12 left-info">
        <p class="z-text" [innerHTML]="t('basicParent/testZText1')"></p>
      </td>
    </tr>

    <ng-template #test45>
      <tr class="row mt-3" *ngIf="data.test.shortTitle === 'D 4-5'; else test34">
        <td class="col-6 left-info">
          <p class="fw-bold" [innerHTML]="t('basicParent/test45Text1')"></p>
          <p class="fst-italic" [innerHTML]="t('basicParent/test45Text2')"></p>
        </td>
        <td class="col-6 right-info">
          <p [innerHTML]="t('basicParent/test45Text3')"></p>
        </td>
      </tr>
    </ng-template>

    <ng-template #test34>
      <tr class="row mt-3" *ngIf="data.test.shortTitle === 'D 3-4'; else defaultTest">
        <td class="col-6 left-info">
          <p class="fw-bold" [innerHTML]="t('basicParent/test34Text1')"></p>
          <p class="fst-italic" [innerHTML]="t('basicParent/test34Text2')"></p>
        </td>
        <td class="col-6 right-info">
          <p [innerHTML]="t('basicParent/test34Text3')"></p>
        </td>
      </tr>
    </ng-template>

    <ng-template #defaultTest>
      <tr class="row mt-3">
        <td class="col-6 left-info">
          <p class="fw-bold" [innerHTML]="t('basicParent/testDefaultText1')"></p>
          <p class="fst-italic" [innerHTML]="t('basicParent/testDefaultText2')"></p>
        </td>
        <td class="col-6 right-info">
          <p [innerHTML]="t('basicParent/testDefaultText3')"></p>
        </td>
      </tr>
    </ng-template>

    <tr class="row justify-content-center" [class]="{ 'mt-3': data.test.shortTitle !== 'D 4-5' }">
      <td>
        <app-section-header order="01" [text]="t('basicParent/header1')"></app-section-header>
      </td>
      <td class="col-6">
        <isophi-sun-graph-chart
          class="d-flex justify-content-center"
          [categoryResults]="data.testData.categoryResults"
          [evaluations]="data.legend"
          [child]="data.child"
          [materialServer]="materialServer"
          [puppeteerApp]="true"
          [zReport]="data.test.shortTitle === 'Z' && true"
        ></isophi-sun-graph-chart>
      </td>
      <td class="col-3 d-flex align-items-end justify-content-center">
        <app-chart-hint [evaluations]="data.legend"></app-chart-hint>
      </td>
    </tr>

    <tr class="row ms-3" [class]="{ 'mt-3': data.test.shortTitle !== 'D 4-5' }">
      <td
        *ngIf="data.test.shortTitle !== 'Z'"
        class="right-info h-note"
        [class]="data.testData.notes.length > 0 && data.testData.notes[0].value?.length > 350 ? 'col-12' : 'col-6'"
      >
        <p>
          <span class="fw-bold">{{ t('basicParent/teacherComment') }}</span
          ><br />
          <span *ngIf="data.testData.notes.length > 0">
            {{ data.testData.notes[0].value }}
          </span>
        </p>
      </td>

      <td *ngIf="data.test.shortTitle === 'Z'" class="col-6 right-info z-info">
        <p [innerHTML]="t('basicParent/testZText3')"></p>
      </td>
    </tr>

    <p
      *ngIf="data.test.shortTitle !== 'Z'"
      class="fw-bold"
      [class]="{ 'mt-5': data.test.shortTitle === 'D 5-7', 'mt-3': data.test.shortTitle === 'D 4-5' || data.test.shortTitle === 'D 3-4' }"
    >
      {{ t('basicParent/sideComment') }} ........................................
    </p>

    <tr class="row">
      <td>
        <app-section-header order="02" [text]="t('basicParent/header2')"></app-section-header>
      </td>
      <ng-container *ngFor="let evaluation of sortLegend(data.legend); let index = index">
        <td class="col-6 emoji-container" [style]="{ '--emoji-color': evaluation.backgroundColor }">
          <div class="emoji">
            <img class="img-fluid" [src]="emojis[index]" />
          </div>
          <div class="emoji-title">
            <strong>{{ evaluation.title }}</strong> {{ t('basicParent/abilityLevel') }}
          </div>
        </td>
        <td class="col-6">
          <p *ngIf="data.test.shortTitle !== 'Z'" [innerHTML]="t(evaluationsText[index])"></p>
          <p *ngIf="data.test.shortTitle === 'Z'" [innerHTML]="t(evaluationsTextZ[index])"></p>
        </td>
      </ng-container>
    </tr>

    <tr class="row mt-3">
      <td class="col-7">
        <app-section-header order="03" [text]="t('basicParent/header3')"></app-section-header>

        <div
          class="support"
          *ngFor="
            let support of [
              ['assets/img/icons/house.png', t('basicParent/supportHead1'), t('basicParent/supportText1')],
              ['assets/img/icons/sun-card.png', t('basicParent/supportHead2'), t('basicParent/supportText2')],
              ['assets/img/icons/person-card.png', t('basicParent/supportHead3'), t('basicParent/supportText3')],
              ['assets/img/icons/book-card.png', t('basicParent/supportHead4'), t('basicParent/supportText4')],
              ['assets/img/icons/meeting.png', t('basicParent/supportHead5'), t('basicParent/supportText5')]
            ];
            let i = index
          "
        >
          <div>
            <img [src]="support[0]" alt="icon" />
          </div>
          <p>
            <ng-container *ngIf="data.test.shortTitle !== 'Z'">
              <span class="fw-bold">{{ support[1] }}</span>
              <br />
              {{ support[2] }}
            </ng-container>
            <ng-container *ngIf="data.test.shortTitle === 'Z'">
              <span class="fw-bold">{{ i === 0 ? t('basicParent/supportHeadZ1') : support[1] }}</span>
              <br />
              {{ i === 0 ? t('basicParent/supportTextZ1') : support[2] }}
            </ng-container>
          </p>
        </div>
      </td>
      <td class="col-5 g-0 card">
        <div class="card-header">
          <img class="card-img-top" src="assets/img/logo/iSophiR_Color.svg" alt="iSophiR_Color" />
          <qrcode [qrdata]="parentUrl" [margin]="1" [width]="74" [errorCorrectionLevel]="'M'"></qrcode>
        </div>
        <div class="card-body">
          <p class="card-text mb-2">
            {{ t('basicParent/forYou') }}
            <span class="fw-bold fst-italic"> iSophi R,</span>
            {{ t('basicParent/premium') }}
          </p>
          <p class="card-text mb-0">{{ t('basicParent/findWebSite') }}</p>
          <p class="card-text fw-bold text-center card-url mb-2">www.rozvojditete.cz</p>
          <p class="card-text fw-bold mb-0">{{ t('basicParent/about') }}</p>
          <ul>
            <li
              *ngFor="
                let text of [
                  t('basicParent/aboutText1'),
                  t('basicParent/aboutText2'),
                  t('basicParent/aboutText3'),
                  t('basicParent/aboutText4')
                ]
              "
            >
              {{ text }}
            </li>
          </ul>
        </div>
      </td>
    </tr>
  </app-report-wrapper>
</ng-container>
