<ng-container *transloco="let t">
  <app-report-wrapper *ngIf="data">
    <app-universal-header-report
      [reportName]="t('comparisonTeacher/reportName')"
      [typeOfReport]="t('shared/reportComparison')"
      [comparison]="true"
      [subTitle]="t('comparisonTeacher/subTitle')"
      [child]="data.child"
      [test]="data.test"
      [testData]="data.testData[0]"
      [testData2]="data.testData[1]"
      [showGenderIcon]="true"
      [showIcon]="true"
      [iconUrl]="'assets/img/icon_comparison.svg'"
      header
    ></app-universal-header-report>

    <div class="d-flex justify-content-end mt-3">
      <app-chart-hint [evaluations]="data.legend"></app-chart-hint>
    </div>

    <tr class="row">
      <td>
        <app-section-header order="01" [text]="t('basicParent/header1')"></app-section-header>
      </td>
      <td class="col-12 g-0 ps-5">
        <ng-container *ngFor="let testData of data.testData; let index = index">
          <app-children-sorted-chart
            [categoryResults]="testData.categoryResults"
            [index]="index"
            [comparison]="true"
            [materialServer]="materialServer"
            [evaluations]="data.legend"
          ></app-children-sorted-chart>
        </ng-container>
      </td>
    </tr>

    <tr class="row section-2">
      <td>
        <app-section-header order="02" [text]="t('basicTeacher/header2')"></app-section-header>
      </td>
      <td class="col-10">
        <div class="d-flex mb-1">
          <app-percentage></app-percentage>
          <div class="percent-compare">
            <p>{{ t('comparisonStem/shift') }}</p>
          </div>
        </div>

        <ng-container *ngFor="let categoryResult of data.testData[0].categoryResults; let index = index">
          <app-category-bar-chart
            [categoryResult]="categoryResult"
            [categoryResult2]="data.testData[1].categoryResults[index]"
            [iconUrl]="materialServer + categoryResult.category.activeIconUrl"
            [comparison]="true"
            [range]="getRanges(data.test.ranges, categoryResult)"
          ></app-category-bar-chart>
        </ng-container>
      </td>
      <td class="col-2 g-0" style="font-size: 12px; margin-top: 2rem">
        <div class="hint-container">
          <ng-container *ngFor="let i of [1, 2]; let index = index">
            <div class="comparison-hint" [ngClass]="{ opacity: index === 0 }">
              <span *ngFor="let evaluation of data.legend" [style]="{ 'background-color': evaluation.backgroundColor }"></span>
              {{ index + 1 }}. {{ t('shared/diagnostika') }}
            </div>
          </ng-container>
          <div class="mt-1">
            <img class="img-fluid icon" src="assets/img/arrow-up.jpg" alt="up" />
            {{ t('shared/increase') }}
          </div>
          <div class="mt-1">
            <img class="img-fluid icon" src="assets/img/arrow-down.png" alt="down" />
            {{ t('shared/decrease') }}
          </div>
          <div class="mt-1">
            <span class="icon ml-1 equal-color">=</span>
            {{ t('shared/equal') }}
          </div>
        </div>
      </td>
    </tr>

    <tr class="row g-0 align-items-center">
      <td class="col-4">
        <app-section-header order="03" [text]="t('basicTeacher/header3')"></app-section-header>
        <div class="mt-5 ms-5">
          <div class="radar-hint">
            <p>1. {{ t('shared/diagnostika') }}</p>
            <span [style]="{ 'background-color': 'rgba(46, 49, 56, 0.4)' }"></span>
          </div>
          <div class="radar-hint">
            <p>2. {{ t('shared/diagnostika') }}</p>
            <span [style]="{ 'background-color': 'rgba(27, 42, 250, 0.5)' }"></span>
          </div>
        </div>
      </td>
      <td class="col-8">
        <isophi-categories-radar-chart
          [categoryResults]="data.testData[0].categoryResults"
          [testData]="data.testData"
          [materialServer]="materialServer"
          typeOfReport="comparison"
          [puppeteerApp]="true"
        ></isophi-categories-radar-chart>
      </td>
    </tr>

    <ng-container *ngIf="data.testData[0].notes.length > 0 && data.testData[1].notes.length > 0; else noNotes">
      <ng-container *ngFor="let noteDiag1 of data.testData[0].notes; let index = index">
        <app-notes-comparison-wraper
          class="d-block"
          [title]="noteDiag1.title"
          [class]="{
            'second-note': index === 1,
            'no-break': !breakPoint(0, data.testData[0].notes[0]) && !breakPoint(1, data.testData[1].notes[0]),
            'no-note': counter < 5 && counter2 < 5,
            'fifth-note':
              index === 4 && (checkFifthNote(data.testData[0].notes.slice(1, 5)) || checkFifthNote(data.testData[0].notes.slice(1, 5)))
          }"
        >
          <ng-container diagnostika-1>
            <app-note-content [comparison]="true" [notes]="data.testData[0].notes" [note]="noteDiag1"></app-note-content>
          </ng-container>

          <ng-container diagnostika-2>
            <app-note-content
              [comparison]="true"
              [notes]="data.testData[1].notes"
              [note]="data.testData[1].notes[index]"
            ></app-note-content>
          </ng-container>
        </app-notes-comparison-wraper>
      </ng-container>
    </ng-container>
    <ng-template #noNotes>
      <div class="ps-5">
        <p>{{ t('shared/emptyNote') }}</p>
      </div>
    </ng-template>
  </app-report-wrapper>
</ng-container>
