<ng-container *transloco="let t">
  <app-report-wrapper *ngIf="data">
    <app-universal-header-report
      [reportName]="t('basicStem/reportName')"
      [typeOfReport]="t('shared/reportBasic')"
      [subTitle]="t('basicStem/subTitle')"
      [child]="data.child"
      [test]="data.test"
      [testData]="data.testData"
      [showGenderIcon]="true"
      [showIcon]="true"
      [iconUrl]="'assets/img/icon_stem.svg'"
      header
    ></app-universal-header-report>

    <div class="d-flex justify-content-end mt-3">
      <app-chart-hint [evaluations]="data.legend"></app-chart-hint>
    </div>

    <tr class="row chart-row p-2 ms-1">
      <td>
        <app-section-header order="01" [text]="t('basicStem/header1')"></app-section-header>
      </td>
      <td class="col-10">
        <app-percentage class="d-flex justify-content-center"></app-percentage>
        <app-category-bar-chart
          *ngFor="let categoryResult of data.testData.categoryResults.stemResults"
          [categoryResult]="categoryResult"
          [iconUrl]="materialServer + categoryResult.category.activeIconUrl"
          [range]="getRanges(data.test.ranges, categoryResult)"
          class="d-block ms-3"
        ></app-category-bar-chart>
      </td>
      <td class="smart col-2">
        <h6 class="fw-bold mt-2">{{ t('basicStem/smartDifficulty') }}</h6>
        <ng-container *ngFor="let categoryResult of data.testData.categoryResults.stemResults">
          <app-smart-circle [categoryResult]="categoryResult" [testType]="data.test.shortTitle"></app-smart-circle>
        </ng-container>
      </td>
      <td class="pie-chart col-10">
        <div class="d-flex flex-column justify-content-center">
          <ng-container *ngFor="let category of data.testData.categoryResults.stemResults">
            <app-hexagon class="mb-2" [iconUrl]="materialServer + category.category.inactiveIconUrl"></app-hexagon>
          </ng-container>
        </div>
        <div class="algorithmic-thinking">
          <div class="line" *ngFor="let category of data.testData.categoryResults.stemResults"></div>
          <app-hexagon [iconUrl]="materialServer + data.testData.categoryResults.computedResult.category.inactiveIconUrl"></app-hexagon>
          <p>
            {{ data.testData.categoryResults.computedResult.category.name }}
          </p>
        </div>
        <isophi-category-doughnut-chart
          class="d-block"
          [categoryResult]="data.testData.categoryResults.computedResult"
          [puppeteerApp]="true"
        ></isophi-category-doughnut-chart>
      </td>
      <td class="smart col-2">
        <app-smart-circle
          [categoryResult]="data.testData.categoryResults.computedResult"
          [testType]="data.test.shortTitle"
        ></app-smart-circle>
      </td>
    </tr>

    <tr class="row">
      <td>
        <app-section-header class="d-block mt-1 ms-4" order="02" [text]="t('basicStem/header2')"></app-section-header>
      </td>
      <td class="col-10">
        <app-category-bar-chart
          *ngFor="let categoryResult of data.testData.categoryResults.otherResults"
          [categoryResult]="categoryResult"
          [iconUrl]="materialServer + categoryResult.category.activeIconUrl"
          [range]="getRanges(data.test.ranges, categoryResult)"
          class="d-block"
          style="margin-left: 2.7rem"
        ></app-category-bar-chart>
      </td>
    </tr>
  </app-report-wrapper>
</ng-container>
