<ng-container *transloco="let t">
  <table class="container-fluid" *ngIf="data">
    <tbody>
      <tr class="row justify-content-center">
        <td class="col-5 d-flex flex-column justify-content-center align-items-end mt-1 ms-1" *ngFor="let child of data.childrenQrCodes">
          <h3 class="child-name fw-bold pe-4">{{ childFullName(child) }}</h3>
          <img class="img-fluid mouse" src="assets/img/mouseqr.png" alt="mouseqr" />
          <qrcode [qrdata]="child.uuid" [margin]="1" [width]="132" [errorCorrectionLevel]="'M'"></qrcode>
          <div class="main">
            <div>
              <p>{{ t('qrCodes/childAction') }}</p>
            </div>
          </div>
          <p class="text-uppercase text-static">{{ t('qrCodes/card') }}</p>
          <img class="img-fluid logo" src="assets/img/logo/iSophi_rastr_dark.png" alt="iSophi_rastr_dark" />
        </td>
      </tr>
    </tbody>
  </table>
</ng-container>
