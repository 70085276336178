import { Component } from '@angular/core';
import { childFullName } from '@isophi/core-ui';
import { TokenChild } from 'isophi-pdfs-api';
import { IsophiData } from '../../../server/data.model';
import { BaseReportComponent } from '../../core/components/base-report/base-report.component';

const reportId = '';
const documentId = 'profileTokens';

export interface ProfileTokensData extends IsophiData {
  documentId: typeof documentId;
  childrenTokens: TokenChild[];
}

@Component({
  selector: 'app-profile-tokens',
  templateUrl: './profile-tokens.component.html',
  styleUrls: ['./profile-tokens.component.scss'],
})
export class ProfileTokensComponent extends BaseReportComponent<ProfileTokensData> {
  get reportId(): string {
    return reportId;
  }

  get documentId(): string {
    return documentId;
  }

  public childFullName = childFullName;
}
