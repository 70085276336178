import { Question, Section } from 'isophi-pdfs-api';

/* eslint-disable @typescript-eslint/naming-convention */
export const sectionTypeEunm = {
  TEXT: 'text',
  QUESTION: 'question'
};

export const questionTypeEnum = {
  BOOL: 'bool',
  TEXT: 'text',
  OPTION: 'option'
};

export const checkNotesQuestions = (qusestions: Question[]): boolean => {
  return qusestions.some((val) => val.value);
};

export const breakPoint = (note: Section): boolean => {
  let counter = 0;

  if (note.sectionType === sectionTypeEunm.QUESTION) {
    for (let i = 0; i < note.subSections.length; i++) {
      const val = note.subSections[i];

      if (note.subSections[i].questions.length > 1) {
        const filteredQuestions = val.questions.filter((val1) => val1.value === 'true');
        counter += filteredQuestions.length;
      } else if (val.questions.length === 1 && val.questions[0].value) {
        counter += val.questions[0].options.length;
      }

      if (counter >= 15) {
        break;
      }
    }
  }

  return Boolean(counter >= 15);
};
