<ng-container *transloco="let t">
  <app-report-wrapper *ngIf="data">
    <app-universal-header-report
      [reportName]="t('orderedGroup/reportName')"
      [subTitle]="orderByText[data.orderBy]"
      [orderdGroup]="true"
      [numberOfChildren]="data.children.length"
      [schoolYears]="data.schoolYears"
      header
    ></app-universal-header-report>

    <div class="category-info">
      <p class="ps-2">{{ t('orderedGroup/groupsName') }}</p>
      <p *ngFor="let category of data.categories">
        <img [src]="materialServer + category.inactiveIconUrl" alt="icon" />
        {{ category.name }}
      </p>
    </div>

    <tr class="row orderd-group">
      <td class="col-9 ps-5">
        <app-chart-hint [evaluations]="data.legend"></app-chart-hint>
      </td>

      <td class="col-12 mt-3">
        <app-children-sorted-chart
          *ngFor="let childResult of data.children; let index = index"
          [orderedGroupCategoryResults]="childResult.categoryResults"
          [child]="childResult.child"
          [index]="index"
          [materialServer]="materialServer"
          [evaluations]="data.legend"
          [typeZ]="true"
        ></app-children-sorted-chart>
      </td>
    </tr>
  </app-report-wrapper>
</ng-container>
