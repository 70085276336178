/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-problematic-categories',
  templateUrl: './problematic-categories.component.html',
  styleUrls: ['./problematic-categories.component.scss']
})
export class ProblematicCategoriesComponent implements OnInit {
  @Input() public problematicCategories: any[];

  @Input() public materialServer: string;

  protected maxSizeGroup = 0;

  protected minSizeGroup = 0;

  public ngOnInit(): void {
    this.findMaxAndMinSizeOfGroups();
    this.sortProblematicCategoriesByGroupSize();
  }

  /**
   * Return icon size according to group size (children count in problematic category).
   */
  public getIconSize(childrenCount: number): number {
    // min pixel 70, max pixel 170
    const pixelBase = 70;
    const pixelRange = 100;

    const maxSizeNormalized = this.maxSizeGroup - this.minSizeGroup;
    const sizeNormalized = childrenCount - this.minSizeGroup;

    if (maxSizeNormalized === 0) {
      return pixelBase + pixelRange;
    }

    const groupSize = Math.round((sizeNormalized / maxSizeNormalized) * 100);
    return pixelBase + Math.round(pixelRange * (groupSize / 100));
  }

  protected findMaxAndMinSizeOfGroups(): void {
    const groupSizes = this.problematicCategories.map((pc) => pc.children.length);
    this.maxSizeGroup = Math.max(...groupSizes);
    this.minSizeGroup = Math.min(...groupSizes);
  }

  protected sortProblematicCategoriesByGroupSize(): void {
    this.problematicCategories.sort((a, b) => {
      return b.children.length - a.children.length;
    });
  }
}
