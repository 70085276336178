<ul>
  <li><a routerLink="basicParent">basicParent</a></li>
  <li><a routerLink="questionnaireParent">questionnaireParent</a></li>
  <li><a routerLink="basicTeacher">basicTeacher</a></li>
  <li><a routerLink="comparisonTeacher">comparisonTeacher</a></li>
  <li><a routerLink="developmentMultireport">developmentMultireport</a></li>
  <li><a routerLink="basicGroup">basicGroup</a></li>
  <li><a routerLink="orderedGroup">orderedGroup</a></li>
  <li><a routerLink="basicStem">basicStem</a></li>
  <li><a routerLink="comparisonStem">comparisonStem</a></li>
  <li><a routerLink="gameScreening">gameScreening</a></li>
  <li><a routerLink="qrCodes">qrCodes</a></li>
  <li><a routerLink="profileTokens">profileTokens</a></li>
</ul>
