<ng-container *transloco="let t">
  <app-report-wrapper *ngFor="let child of data.childrenTokens" [reportName]="'profileTokens'">
    <div profileTokensHeader class="row mb-4">
      <div class="col-8">
        <h3 [innerHTML]="t('profileTokens/header')"></h3>
      </div>
      <div class="col-4">
        <h3 class="position-relative">
          {{ childFullName(child) }}<br /><img class="home-img" src="assets/img/navod_budka_color.svg" alt="home" />
        </h3>
      </div>
    </div>

    <tr class="row mt-4">
      <td class="col-12">
        <p [innerHTML]="t('profileTokens/intro')" class="mb-0"></p>
      </td>
    </tr>

    <tr class="row mt-4">
      <td class="col-12">
        <p class="mb-1 text-decoration-underline">{{ t('profileTokens/steps') }}</p>
        <ol class="ms-4">
          <li
            *ngFor="
              let step of [
                t('profileTokens/step1'),
                t('profileTokens/step2'),
                t('profileTokens/step3', { token: child.token }),
                t('profileTokens/step4')
              ]
            "
            [innerHTML]="step"
          ></li>
        </ol>
      </td>
    </tr>

    <div profileTokensFooter class="text-start">
      <p>{{ t('profileTokens/wishes') }}</p>
      <p>{{ t('profileTokens/team') }}</p>
    </div>
  </app-report-wrapper>
</ng-container>
