import { Injectable } from '@angular/core';
import { config } from '../../../server/config';
import { IsophiData } from '../../../server/data.model';

import { dataServiceMockData } from './data.service-mock-data';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public getData<Data extends IsophiData>(reportId = '', documentId = ''): Data {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = (window as any)[config.windowDataKey] as Data;
    return data || dataServiceMockData[reportId] || dataServiceMockData[documentId] || {};
  }
}
