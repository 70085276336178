import { Component } from '@angular/core';
import { BaseReportComponent } from '../../core/components/base-report/base-report.component';
import { Child, Legend, MultiResult, MultiResultTest, TestMultiResult } from 'isophi-pdfs-api';
import { IsophiData } from '../../../server/data.model';

const reportId = 'developmentMultireport';
const documentId = '';

export interface DevelopmentMultireportData extends IsophiData {
  reportId: typeof reportId;
  child: Child;
  tests: MultiResultTest[];
  childResults: MultiResult[];
  legend: Legend[];
}

@Component({
  selector: 'app-development-multi-report',
  templateUrl: './development-multi-report.component.html',
  styleUrls: ['./development-multi-report.component.scss'],
})
export class DevelopmentMultiReportComponent extends BaseReportComponent<DevelopmentMultireportData> {
  get reportId(): string {
    return reportId;
  }

  get documentId(): string {
    return documentId;
  }

  public textCol(length: number) {
    let col = 2;

    if (length <= 7) {
      col += 7 - length;
    }
    return `col-${col}`;
  }

  public arrowCol(length: number) {
    return `col-${length}`;
  }

  public checkTestResult(test: MultiResultTest, testResults: TestMultiResult[]): TestMultiResult {
    return testResults.filter(
      (val) => val.test.shortTitle === test.test.shortTitle && val.finishedAt === test.finishedAt && val.evaluation !== null
    )[0];
  }
}
