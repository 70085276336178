<ng-container *transloco="let t">
  <app-report-wrapper *ngIf="data">
    <app-universal-header-report
      [reportName]="t('basicTeacher/reportName')"
      [typeOfReport]="t('shared/reportBasic')"
      [subTitle]="t('basicTeacher/subTitle')"
      [child]="data.child"
      [test]="data.test"
      [testData]="data.testData"
      [showGenderIcon]="true"
      [polar]="true"
      [showIcon]="true"
      [iconUrl]="'assets/img/icon_teacher.svg'"
      header
    ></app-universal-header-report>

    <div class="col-4 polar-chart">
      <isophi-categories-polar-area-chart
        [categories]="data.testData.categoryResults"
        [materialServer]="materialServer"
        [evaluations]="data.legend"
        [puppeteerApp]="true"
      >
        <app-section-header class="mb-0" order="01" [text]="t('shared/header1')"></app-section-header>
        <h6 class="fw-bold text-end" [innerHTML]="t('shared/subHeader1')"></h6>
      </isophi-categories-polar-area-chart>
    </div>

    <tr class="row section-2">
      <td>
        <app-section-header order="02" [text]="t('basicTeacher/header2')"></app-section-header>
      </td>
      <td class="col-8 d-flex flex-column">
        <app-percentage class="d-flex justify-content-center"></app-percentage>
        <app-category-bar-chart
          *ngFor="let categoryResult of data.testData.categoryResults"
          [categoryResult]="categoryResult"
          [iconUrl]="materialServer + categoryResult.category.activeIconUrl"
          [range]="getRanges(data.test.ranges, categoryResult)"
        ></app-category-bar-chart>
      </td>
      <td class="col-4">
        <app-chart-hint [evaluations]="data.legend"></app-chart-hint>
        <div class="mt-3 notes">
          <app-note-content [note]="data.testData.notes[0]"></app-note-content>
        </div>
      </td>
    </tr>

    <tr class="row align-items-center">
      <td class="col-3">
        <app-section-header order="03" [text]="t('basicTeacher/header3')"></app-section-header>
      </td>
      <td class="col-9">
        <isophi-categories-radar-chart
          [categoryResults]="data.testData.categoryResults"
          [materialServer]="materialServer"
          [typeOfReport]="'basic'"
          [puppeteerApp]="true"
        ></isophi-categories-radar-chart>
      </td>
    </tr>

    <tr class="row border-top border-bottom border-dark">
      <td class="col-5 border-end border-dark py-3">
        <app-note-content [note]="data.testData.notes[2]"></app-note-content>
      </td>
      <td class="col-7 py-3 pe-4">
        <app-note-content [note]="data.testData.notes[1]"></app-note-content>
      </td>
    </tr>

    <ng-container *ngIf="data.testData.notes.length > 4; else lessNotes">
      <tr
        class="row border-top border-bottom border-dark"
        [class]="{
          split:
            (data.testData.notes[3].sectionType === sectionTypeEunm.TEXT && data.testData.notes[3].value?.length > 750) ||
            (data.testData.notes[4].sectionType === sectionTypeEunm.TEXT && data.testData.notes[4].value?.length > 750)
        }"
      >
        <td class="col-5 border-end border-dark py-3">
          <app-note-content *ngIf="data.testData.notes[3]" [note]="data.testData.notes[3]"></app-note-content>
        </td>
        <td class="col-7 py-3 pe-4">
          <app-note-content *ngIf="data.testData.notes[4]" [note]="data.testData.notes[4]"></app-note-content>
        </td>
      </tr>

      <tr
        class="row split py-3"
        *ngIf="data.testData.notes.length >= 6 && (data.testData.notes[5]?.value || data.testData.notes[6]?.value)"
      >
        <td class="col-12">
          <app-note-content *ngIf="data.testData.notes[5]" [note]="data.testData.notes[5]"></app-note-content>
        </td>
        <td class="col-12 py-3">
          <app-note-content *ngIf="data.testData.notes[6]" [note]="data.testData.notes[6]"></app-note-content>
        </td>
      </tr>
    </ng-container>

    <ng-template #lessNotes>
      <tr
        class="row"
        *ngIf="data.testData.notes[3]"
        [class]="{
          split: data.testData.notes[3].sectionType === sectionTypeEunm.TEXT && data.testData.notes[3].value?.length > 750,
          'mt-3': data.testData.notes[3].sectionType === sectionTypeEunm.TEXT && data.testData.notes[3].value?.length <= 750
        }"
      >
        <td class="col-12">
          <app-note-content [note]="data.testData.notes[3]"></app-note-content>
        </td>
      </tr>
    </ng-template>
  </app-report-wrapper>
</ng-container>
