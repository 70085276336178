import { Component, Input } from '@angular/core';
import { Legend } from 'isophi-pdfs-api';

@Component({
  selector: 'app-chart-hint',
  templateUrl: './chart-hint.component.html',
  styleUrls: ['./chart-hint.component.scss']
})
export class ChartHintComponent {
  @Input() public evaluations: Legend[];

  @Input() public lock?: boolean;
}
