<ng-container *transloco="let t">
  <div class="sorted-report row g-0 ps-2" [class]="{ 'py-2': !typeZ, 'py-3': typeZ }">
    <div class="child-info col-1" *ngIf="comparison; else sorted">
      <div class="row g-0">
        <p class="col-2 me-1">{{ index + 1 }}.</p>
        <p class="col-9">{{ t('shared/diagnostika') }}:</p>
      </div>
    </div>
    <ng-template #sorted>
      <div class="child-info col-4" style="padding-left: 2rem">
        <div class="row g-0">
          <p class="col-1">{{ index + 1 }}</p>
          <p class="col-6">{{ childFullName(child) }}</p>
          <p class="col-4 text-end">
            <img [src]="getGenderIcon(child?.gender)" [alt]="child?.gender" />
          </p>
        </div>
      </div>
    </ng-template>

    <div class="categories-info col-11" *ngIf="comparison; else noComparison" [ngClass]="{ opacity: comparison && index === 0 }">
      <div
        class="row g-0"
        [class]="{ 'justify-content-center': firstHalfcategories.length <= 7, 'justify-content-end': firstHalfcategories.length > 7 }"
      >
        <ng-container *ngFor="let filteredCategories of firstHalfcategories">
          <app-categories-with-color
            *ngIf="filteredCategories.length"
            [categoryResults]="filteredCategories"
            [materialServer]="materialServer"
            [comparison]="comparison"
            class="row g-0"
          ></app-categories-with-color>
        </ng-container>
      </div>
    </div>
    <ng-template #noComparison>
      <div class="categories-info col-8">
        <div class="row g-0">
          <ng-container *ngIf="typeZ; else normalTest">
            <div
              *ngFor="let categoryResult of orderedGroupCategoryResults"
              [style.background-color]="categoryResult.evaluation.backgroundColor"
              class="z-section mx-2"
            >
              <img [src]="materialServer + categoryResult.category.inactiveIconUrl" alt="icon" />
            </div>
          </ng-container>

          <ng-template #normalTest>
            <ng-container *ngFor="let filteredCategories of firstHalfcategories">
              <app-categories-with-color
                *ngIf="filteredCategories.length"
                [categoryResults]="filteredCategories"
                [materialServer]="materialServer"
                class="row g-0"
              ></app-categories-with-color>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </div>
</ng-container>
