import { Component } from '@angular/core';
import { childFullName } from '@isophi/core-ui';
import { Child } from 'isophi-pdfs-api';
import { BaseReportComponent } from '../../core/components/base-report/base-report.component';

import { IsophiData } from '../../../server/data.model';

const reportId = '';
const documentId = 'qrCodes';

export interface QrCodesData extends IsophiData {
  documentId: typeof documentId;
  childrenQrCodes: Child[];
}

@Component({
  selector: 'app-qr-codes',
  templateUrl: './qr-codes.component.html',
  styleUrls: ['./qr-codes.component.scss'],
})
export class QrCodesComponent extends BaseReportComponent<QrCodesData> {
  get reportId(): string {
    return reportId;
  }

  get documentId(): string {
    return documentId;
  }

  public childFullName = childFullName;
}
