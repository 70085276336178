import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreUiModule } from '@isophi/core-ui';
import { TranslocoModule } from '@ngneat/transloco';
import { NgChartsModule } from 'ng2-charts';

import { CategoriesWithColorComponent } from './components/categories-with-color/categories-with-color.component';
import { CategoryBarChartComponent } from './components/category-bar-chart/category-bar-chart.component';
import { ChartHintComponent } from './components/chart-hint/chart-hint.component';
import { ChildrenSortedChartComponent } from './components/children-sorted-chart/children-sorted-chart.component';
import { FooterComponent } from './components/footer/footer.component';
import { HexagonComponent } from './components/hexagon/hexagon.component';
import { NoteContentComponent } from './components/note-content/note-content.component';
import { NotesComparisonWraperComponent } from './components/notes-comparison-wraper/notes-comparison-wraper.component';
import { PercentageComponent } from './components/percentage/percentage.component';
import { ProblematicCategoriesComponent } from './components/problematic-categories/problematic-categories.component';
import { ReportWrapperComponent } from './components/report-wrapper/report-wrapper.component';
import { SectionHeaderComponent } from './components/section-header/section-header.component';
import { SmartCircleComponent } from './components/smart-circle/smart-circle.component';
import { UniversalHeaderReportComponent } from './components/universal-header-report/universal-header-report.component';

const COMPONENTS = [
  UniversalHeaderReportComponent,
  SectionHeaderComponent,
  ReportWrapperComponent,
  FooterComponent,
  ChartHintComponent,
  CategoryBarChartComponent,
  HexagonComponent,
  PercentageComponent,
  ChildrenSortedChartComponent,
  CategoriesWithColorComponent,
  NotesComparisonWraperComponent,
  NoteContentComponent,
  SmartCircleComponent,
  ProblematicCategoriesComponent,
];
@NgModule({
  imports: [CommonModule, CoreUiModule, NgChartsModule, TranslocoModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class SharedModule {
  public static forRoot() {
    return {
      ngModule: SharedModule,
    };
  }
}
