import { Component } from '@angular/core';
import { CategoryResult, Child, EduActivity, Legend, Questionnaire, QuestionnaireData, Test } from 'isophi-pdfs-api';
import { IsophiData } from '../../../server/data.model';
import { BaseReportComponent } from '../../core/components/base-report/base-report.component';
import { emojis, evaluationsText } from './questionnaire-parent-report.data';


const reportId = 'questionnaireParent';
const documentId = '';

export interface QuestionnaireParentData extends IsophiData {
  reportId: typeof reportId;
  child: Child;
  test: Test;
  questionnaire: Questionnaire;
  questionnaireData: QuestionnaireData;
  legend: Legend[];
  eduActivities: EduActivity[];
}

@Component({
  selector: 'app-questionnaire-parent-report',
  templateUrl: './questionnaire-parent-report.component.html',
  styleUrls: ['./questionnaire-parent-report.component.scss'],
})
export class QuestionnaireParentReportComponent extends BaseReportComponent<QuestionnaireParentData> {
  get reportId(): string {
    return reportId;
  }

  get documentId(): string {
    return documentId;
  }

  public evaluationsText = evaluationsText;

  public emojis = emojis;

  public lock = false;

  public sortLegend(legend: Legend[]) {
    return legend.sort((a, b) => b.code - a.code);
  }

  public reformatCategoryResults(categoryResults: CategoryResult[], lockedCategories: Array<string>) {
    return categoryResults?.map((val) => {
      const lockCategory = lockedCategories.includes(val.category.uuid);

      if (lockCategory) {
        this.lock = lockCategory;
      }

      return {
        category: val.category,
        evaluation: {
          ...val.evaluation,
          code: lockCategory ? -1 : val.evaluation.code,
          backgroundColor: lockCategory ? '#a3a3a3' : val.evaluation.backgroundColor
        },
        maxScore: val.maxScore,
        score: val.score,
        percent: val.percent,
      };
    });
  }

  public reformatEduActivities(eduActivities: EduActivity[], lockedCategories: Array<string>) {
    return eduActivities?.map((val) => {
      const lockCategory = lockedCategories.includes(val.category.uuid);

      if (lockCategory) {
        this.lock = lockCategory;
      }

      return {
        ...val,
        category: val.category,
        evaluation: {
          ...val.evaluation,
          code: lockCategory ? -1 : val.evaluation.code,
          backgroundColor: lockCategory ? '#a3a3a3' : val.evaluation.backgroundColor
        },
      };
    });
  }
}
