import { ProfileTokensData } from '../../document/profile-tokens/profile-tokens.component';
import { QrCodesData } from '../../document/qr-codes/qr-codes.component';
import { BasicGroupData } from '../../reports/basic-group-report/basic-group-report.component';
import { BasicParentData } from '../../reports/basic-parent-report/basic-parent-report.component';
import { BasicStemData } from '../../reports/basic-stem-report/basic-stem-report.component';
import { BasicTeacherData } from '../../reports/basic-teacher-report/basic-teacher-report.component';
import { ComparisonStemData } from '../../reports/comparison-stem-report/comparison-stem-report.component';
import { ComparisonTeacherData } from '../../reports/comparison-teacher-report/comparison-teacher-report.component';
import { DevelopmentMultireportData } from '../../reports/development-multi-report/development-multi-report.component';
import { GameScreeningData } from '../../reports/game-screening-report/game-screening-report.component';
import { OrderedGroupData } from '../../reports/ordered-group-report/ordered-group-report.component';
import { QuestionnaireParentData } from '../../reports/questionnaire-parent-report/questionnaire-parent-report.component';
import childData from '../utils/data/child';
import { child, legend, test, testComparisonData, testComparisonStemData } from '../utils/data/comparisonData';
import evaluationsData from '../utils/data/evaluations';
import { scenario, scenarioCategoryResults } from '../utils/data/gameData';
import { groupCategoryResults, groupLegend, groupProblematicCategories, groupReportInfo, groupTest } from '../utils/data/groupReportData';
import { childResults, multiTests } from '../utils/data/multiReportData';
import { children, orderBy, orderCategories, orderLegend, orderTest, schoolYears } from '../utils/data/orderedReportData';
import { childrenProfileTokensData } from '../utils/data/profileTokensData';
import { childrenQrCodesData } from '../utils/data/qrData';
import { eduActivities, questionnaire, questionnaireData } from '../utils/data/questionnaireReportData';
import { testData, testDataData, testStemData } from '../utils/data/testData';

export const dataServiceMockData = {
  basicParent: {
    reportId: 'basicParent',
    child: childData,
    test: testData,
    testData: testDataData,
    legend: evaluationsData,
  } as BasicParentData,
  questionnaireParent: {
    reportId: 'questionnaireParent',
    child: childData,
    test: testData,
    questionnaire: questionnaire,
    questionnaireData: questionnaireData,
    legend: evaluationsData,
    eduActivities: eduActivities,
  } as QuestionnaireParentData,
  basicTeacher: {
    reportId: 'basicTeacher',
    child: childData,
    test: testData,
    testData: testDataData,
    legend: evaluationsData,
  } as BasicTeacherData,
  comparisonTeacher: {
    reportId: 'comparisonTeacher',
    child: child,
    test: test,
    testData: testComparisonData,
    legend: legend,
  } as ComparisonTeacherData,
  developmentMultireport: {
    reportId: 'developmentMultireport',
    child: child,
    tests: multiTests,
    childResults: childResults,
    legend: legend,
  } as DevelopmentMultireportData,
  basicGroup: {
    reportId: 'basicGroup',
    groupReportInfo: groupReportInfo,
    categoryResults: groupCategoryResults,
    problematicCategories: groupProblematicCategories,
    test: groupTest,
    legend: groupLegend,
  } as BasicGroupData,
  orderedGroup: {
    reportId: 'orderedGroup',
    children: children,
    test: orderTest,
    schoolYears: schoolYears,
    orderBy: orderBy,
    categories: orderCategories,
    legend: orderLegend,
  } as OrderedGroupData,
  basicStem: {
    reportId: 'basicStem',
    child: childData,
    test: testData,
    testData: testStemData,
    legend: legend,
  } as BasicStemData,
  comparisonStem: {
    reportId: 'comparisonStem',
    child: child,
    test: test,
    testData: testComparisonStemData,
    legend: legend,
  } as ComparisonStemData,
  gameScreening: {
    reportId: 'gameScreening',
    child: child,
    scenario: scenario,
    scenarioCategoryResults: scenarioCategoryResults,
    legend: legend,
  } as GameScreeningData,
  qrCodes: {
    documentId: 'qrCodes',
    childrenQrCodes: childrenQrCodesData,
  } as QrCodesData,
  profileTokens: {
    documentId: 'profileTokens',
    childrenTokens: childrenProfileTokensData,
  } as ProfileTokensData,
};
