import { Component } from '@angular/core';
import { childFullName, roundAge } from '@isophi/core-ui';
import { CategoryResult, GroupCategoryResult, GroupReportInfo, Legend, Test } from 'isophi-pdfs-api';
import { IsophiData } from '../../../server/data.model';
import { BaseReportComponent } from '../../core/components/base-report/base-report.component';
import { getRanges } from '../../core/utils/ranges.utils';

const reportId = 'basicGroup';
const documentId = '';

export interface BasicGroupData extends IsophiData {
  reportId: typeof reportId;
  groupReportInfo: GroupReportInfo;
  categoryResults: CategoryResult[];
  problematicCategories: GroupCategoryResult[];
  test: Test;
  legend: Legend[];
}

@Component({
  selector: 'app-basic-group-report',
  templateUrl: './basic-group-report.component.html',
  styleUrls: ['./basic-group-report.component.scss'],
})
export class BasicGroupReportComponent extends BaseReportComponent<BasicGroupData> {
  get reportId(): string {
    return reportId;
  }

  get documentId(): string {
    return documentId;
  }

  public getRanges = getRanges;

  public childFullName = childFullName;

  public roundAge = roundAge;
}
