import { Scenario, ScenarioCategoryResult } from 'isophi-pdfs-api';

export const scenario: Scenario = {
  uuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
  game: {
    uuid: '84d4320d-5be2-4db6-b995-3db1c2724333',
    name: 'Screening'
  },
  name: 'Screening Test Scenario 1',
  created: '2022-08-09T10:11:10Z',
  modified: '2022-08-09T10:11:10Z',
  color: '#ffffff',
  shortTitle: 'M5-7 T1',
  productCodename: 'screening'
};

export const scenarioCategoryResults: ScenarioCategoryResult = {
  childUuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
  finishedAt: '2022-08-22T14:24:32+02:00',
  categoryResults: [
    {
      category: {
        id: 4,
        uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
        name: 'Časová orientace',
        activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
        color: '#46C3C6'
      },
      maxScore: 400.0,
      score: 183.0,
      percent: 45.75,
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#fabf21',
        textColor: '#000000'
      },
      taskResults: [
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        },
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        }
      ]
    },
    {
      category: {
        id: 4,
        uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
        name: 'Časová orientace',
        activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
        color: '#46C3C6'
      },
      maxScore: 400.0,
      score: 183.0,
      percent: 45.75,
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#fabf21',
        textColor: '#000000'
      },
      taskResults: [
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        },
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        },
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        }
      ]
    },
    {
      category: {
        id: 4,
        uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
        name: 'Časová orientace',
        activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
        color: '#46C3C6'
      },
      maxScore: 400.0,
      score: 183.0,
      percent: 45.75,
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#fabf21',
        textColor: '#000000'
      },
      taskResults: [
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        },
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        },
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        },
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        }
      ]
    },
    {
      category: {
        id: 4,
        uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
        name: 'Časová orientace',
        activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
        color: '#46C3C6'
      },
      maxScore: 400.0,
      score: 183.0,
      percent: 45.75,
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#fabf21',
        textColor: '#000000'
      },
      taskResults: [
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        },
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        },
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        }
      ]
    },
    {
      category: {
        id: 4,
        uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
        name: 'Časová orientace',
        activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
        color: '#46C3C6'
      },
      maxScore: 400.0,
      score: 183.0,
      percent: 45.75,
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#fabf21',
        textColor: '#000000'
      },
      taskResults: [
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        },
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        }
      ]
    },
    {
      category: {
        id: 4,
        uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
        name: 'Časová orientace',
        activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
        color: '#46C3C6'
      },
      maxScore: 400.0,
      score: 183.0,
      percent: 45.75,
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#fabf21',
        textColor: '#000000'
      },
      taskResults: [
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        },
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        }
      ]
    },
    {
      category: {
        id: 4,
        uuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
        name: 'Časová orientace',
        activeIconUrl: '/media/uploaded/tags/active/category/cas.svg',
        inactiveIconUrl: '/media/uploaded/tags/inactive/category/cas.svg',
        color: '#46C3C6'
      },
      maxScore: 400.0,
      score: 183.0,
      percent: 45.75,
      evaluation: {
        title: 'Snížená úroveň dovednosti',
        code: 1,
        backgroundColor: '#fabf21',
        textColor: '#000000'
      },
      taskResults: [
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        },
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        },
        {
          scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
          maxScore: '200.0000000000',
          percentage: '50.00000',
          score: '100.0000000000',
          order: 0,
          evaluationBackgroundColor: '#fabf21',
          evaluationTextColor: '#000000',
          evaluationTitle: 'Snížená úroveň dovednosti',
          evaluationCode: 1,
          task: {
            uuid: 'b151cf50-a677-4468-ae7e-d1e3c41efd1f',
            categoryUuid: '91785b30-f2c9-4fba-a37d-9774f5185516',
            created: '2022-08-22T14:24:35.475009+02:00',
            modified: '2022-08-22T14:24:35.475009+02:00',
            name: 'Časová orientace',
            parentUuid: 'string'
          }
        }
      ]
    }
  ],
  scenarioUuid: '4b685e91-4000-4a1b-ae29-6d4f47ea34cb',
  uuid: '3c383141-9556-4b7f-af28-d9f62dd821be'
};
