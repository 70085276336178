<ng-container *transloco="let t">
  <tr class="row border-top border-dark p-3">
    <h6 class="col-12 fw-bold mb-3 text-center">{{ title }}</h6>
    <td class="col-6" style="min-height: 7rem">
      <div class="subtitle-1 ps-5 py-1 mb-3">1. {{ t('shared/diagnostika') }}</div>
      <ng-content select="[diagnostika-1]"></ng-content>
    </td>
    <td class="col-6">
      <div class="subtitle-2 ps-5 py-1 mb-3">2. {{ t('shared/diagnostika') }}</div>
      <ng-content select="[diagnostika-2]"></ng-content>
    </td>
  </tr>
</ng-container>
