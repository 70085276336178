<ng-container *transloco="let t">
  <ng-container *ngIf="!comparison; else compare">
    <h6
      *ngIf="
        (note?.title && !note?.title.includes('interní') && !note?.title.includes('Interní')) ||
        ((note?.title.includes('interní') || note?.title.includes('Interní')) && note.value)
      "
      class="fw-bold mb-4"
    >
      {{ note?.title }}
    </h6>
    <ng-container *ngIf="note?.sectionType === sectionTypeEunm.QUESTION; else noSubSections">
      <ng-container *ngFor="let subSection of note.subSections; let i = index">
        <div class="mb-3 ps-4">
          <h6 class="fw-bold">{{ subSection.title }}</h6>
          <ng-container *ngIf="checkNotesQuestions(subSection.questions); else noQuestions">
            <ul [class]="{ square: subSection.questions.length > 1, circle: subSection.questions.length === 1 }">
              <ng-container *ngFor="let question of subSection.questions">
                <ng-container *ngIf="question.questionType === questionTypeEnum.BOOL && question.value === 'true'; else noBool">
                  <li>{{ question.title }}</li>
                </ng-container>
                <ng-template #noBool>
                  <ng-container *ngIf="question.questionType === questionTypeEnum.OPTION; else noOption">
                    <ng-container *ngFor="let option of question.options">
                      <li [class]="{ selected: +option.id === +question.value }"><span></span>{{ option.title }}</li>
                    </ng-container>
                  </ng-container>
                </ng-template>
                <ng-template #noOption>
                  <ng-container *ngIf="question.questionType === questionTypeEnum.TEXT && question.value">
                    <p>{{ question.value }}</p>
                  </ng-container>
                </ng-template>
              </ng-container>
            </ul>
          </ng-container>
          <ng-template #noQuestions>
            <p>{{ t('shared/emptyNote') }}</p>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #noSubSections>
      <ng-container *ngIf="note?.sectionType === sectionTypeEunm.TEXT && note.value; else noText">
        <div class="mb-3 ps-4">
          <p [innerHTML]="replacedNote(note.value)"></p>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #noText>
      <p *ngIf="!note?.title.includes('interní') && !note?.title.includes('Interní')" class="ps-4">{{ t('shared/emptyNote') }}</p>
    </ng-template>
  </ng-container>

  <ng-template #compare>
    <ng-container *ngIf="note?.sectionType === sectionTypeEunm.QUESTION; else noSubSections">
      <ng-container *ngFor="let subSection of note.subSections; let i = index">
        <div class="mb-3 ps-5" [class]="{ split: breakPoint(notes[0]) && i === 1 }">
          <h6 class="fw-bold">{{ subSection.title }}</h6>
          <ng-container *ngIf="checkNotesQuestions(subSection.questions); else noQuestions">
            <ul [class]="{ square: subSection.questions.length > 1, circle: subSection.questions.length === 1 }">
              <ng-container *ngFor="let question of subSection.questions">
                <ng-container *ngIf="question.questionType === questionTypeEnum.BOOL && question.value === 'true'; else noBool">
                  <li>{{ question.title }}</li>
                </ng-container>
                <ng-template #noBool>
                  <ng-container *ngIf="question.questionType === questionTypeEnum.OPTION; else noOption">
                    <ng-container *ngFor="let option of question.options">
                      <li [class]="{ selected: +option.id === +question.value }"><span></span>{{ option.title }}</li>
                    </ng-container>
                  </ng-container>
                </ng-template>
                <ng-template #noOption>
                  <ng-container *ngIf="question.questionType === questionTypeEnum.TEXT && question.value">
                    <p>{{ question.value }}</p>
                  </ng-container>
                </ng-template>
              </ng-container>
            </ul>
          </ng-container>
          <ng-template #noQuestions>
            <p>{{ t('shared/emptyNote') }}</p>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #noSubSections>
      <ng-container *ngIf="note?.sectionType === sectionTypeEunm.TEXT && note.value; else noText">
        <div class="mb-3 ps-5">
          <p>{{ note.value }}</p>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #noText>
      <p class="ps-5">{{ t('shared/emptyNote') }}</p>
    </ng-template>
  </ng-template>
</ng-container>
