<div class="d-flex align-items-center" [class]="{ 'mt-3': !comparison, 'mt-2': comparison }" *ngIf="categoryResult || taskResult">
  <app-hexagon *ngIf="iconUrl" [iconUrl]="iconUrl"></app-hexagon>
  <div *ngIf="categoryResult; else taskresult" class="me-3 category-header">
    <p class="category-name">
      {{ categoryResult.category.name }}
    </p>
    <div class="line" *ngIf="!comparison"><hr /></div>
  </div>

  <ng-template #taskresult>
    <div class="me-2 category-header">
      <p class="category-name">
        {{ taskResult.task.name }}
      </p>
      <div class="line" *ngIf="!comparison"><hr /></div>
    </div>
  </ng-template>

  <div class="row g-0" *ngIf="comparison; else show" [style]="{ width: '72%' }">
    <ng-container *ngFor="let catResult of [categoryResult, categoryResult2]; let index = index">
      <div class="col-9 progress progress-comparison round-right" [ngClass]="{ opacity: index === 0 }">
        <div
          [ngStyle]="{
            width: math.round(catResult.percent) + '%',
            'background-color': catResult.evaluation.backgroundColor
          }"
          aria-valuemin="0"
          class="progress-bar round-right"
          role="progressbar"
        ></div>
        <ng-container *ngFor="let i of [].constructor(+catResult.maxScore); let index = index">
          <div
            *ngIf="range && range.includes(index); else dotted"
            class="vertical-lines-comparison"
            [ngStyle]="{
              left: calcDistance(index),
              borderColor: 'rgba(0, 0, 0,' + calcBorderOpacity(index, +categoryResult.percent, +categoryResult.maxScore) + ')'
            }"
          ></div>
          <ng-template #dotted>
            <div
              *ngIf="index > 0"
              class="dotted-line"
              [ngStyle]="{
                left: calcDistance(index),
                borderColor: 'rgba(0, 0, 0,' + calcBorderOpacity(index, +catResult.percent, +catResult.maxScore) + ')'
              }"
            ></div>
          </ng-template>
        </ng-container>
      </div>
      <div class="col-3 d-flex d-flex justify-content-between align-items-center" [ngClass]="{ opacity: index === 0 }">
        <p class="percent">{{ scorePercent(+catResult.percent) }}%</p>
        <ng-container *ngIf="index === 1">
          <div class="icon less-color" *ngIf="+categoryResult.score > +categoryResult2.score">
            <img class="img-fluid" [src]="comparisonScore(+categoryResult.percent, +categoryResult2.percent)" alt="icon" />
            {{ score }}
          </div>
          <div class="icon greater-color" *ngIf="+categoryResult.score < +categoryResult2.score">
            <img class="img-fluid" [src]="comparisonScore(+categoryResult.percent, +categoryResult2.percent)" alt="icon" />
            {{ score }}
          </div>
          <div class="icon equal-color" *ngIf="+categoryResult.score === +categoryResult2.score">
            <span>=</span>
            {{ score }}
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <ng-template #show>
    <ng-container *ngIf="categoryResult; else noCategoryResult">
      <div class="progress round-right w-50">
        <div
          [ngStyle]="{
            width: math.round(categoryResult.percent) + '%',
            'background-color': categoryResult.evaluation.backgroundColor
          }"
          aria-valuemin="0"
          class="progress-bar round-right"
          role="progressbar"
        ></div>
        <ng-container *ngIf="showPoints">
          <ng-container *ngFor="let i of [].constructor(+categoryResult.maxScore); let index = index">
            <div
              *ngIf="range && range.includes(index); else dotted"
              class="vertical-lines"
              [ngStyle]="{
                left: calcDistance(index),
                borderColor: 'rgba(0, 0, 0,' + calcBorderOpacity(index, +categoryResult.percent, +categoryResult.maxScore) + ')'
              }"
            ></div>
            <ng-template #dotted>
              <div
                *ngIf="index > 0"
                class="dotted-line"
                [ngStyle]="{
                  left: calcDistance(index),
                  borderColor: 'rgba(0, 0, 0,' + calcBorderOpacity(index, +categoryResult.percent, +categoryResult.maxScore) + ')'
                }"
              ></div>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
      <p class="percent" *ngIf="game; else noGame">{{ scorePercent(+categoryResult.percent) }}%</p>
      <ng-template #noGame>
        <p class="percent">
          {{ categoryResult.score | number: '1.0-0' }} /
          {{ categoryResult.maxScore | number: '1.0-0' }}
        </p>
      </ng-template>
    </ng-container>

    <ng-template #noCategoryResult>
      <div class="progress round-right w-50">
        <div
          [ngStyle]="{
            width: math.round(+taskResult.percentage) + '%',
            'background-color': taskResult.evaluationBackgroundColor
          }"
          aria-valuemin="0"
          class="progress-bar round-right"
          role="progressbar"
        ></div>
      </div>
      <p class="percent" *ngIf="game; else noGame">{{ scorePercent(+taskResult.percentage) }}%</p>

      <ng-template #noGame>
        <p class="percent">
          {{ taskResult.score | number: '1.0-0' }} /
          {{ taskResult.maxScore | number: '1.0-0' }}
        </p>
      </ng-template>
    </ng-template>
  </ng-template>
</div>
