import { Component } from '@angular/core';
import { BaseReportComponent } from '../../core/components/base-report/base-report.component';
import { Child, Legend, Scenario, ScenarioCategoryResult } from 'isophi-pdfs-api';
import { IsophiData } from '../../../server/data.model';

const reportId = 'gameScreening';
const documentId = '';

export interface GameScreeningData extends IsophiData {
  reportId: typeof reportId;
  child: Child;
  scenario: Scenario;
  scenarioCategoryResults: ScenarioCategoryResult;
  legend: Legend[];
}

@Component({
  selector: 'app-game-screening-report',
  templateUrl: './game-screening-report.component.html',
  styleUrls: ['./game-screening-report.component.scss'],
})
export class GameScreeningReportComponent extends BaseReportComponent<GameScreeningData> {
  get reportId(): string {
    return reportId;
  }

  get documentId(): string {
    return documentId;
  }
}
