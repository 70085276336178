import { Component } from '@angular/core';
import { BaseReportComponent } from '../../core/components/base-report/base-report.component';
import { checkNotesQuestions, questionTypeEnum, sectionTypeEunm } from '../../core/utils/notes.utils';
import { getRanges } from '../../core/utils/ranges.utils';
import { Child, Legend, Section, Test, TestData } from 'isophi-pdfs-api';
import { IsophiData } from '../../../server/data.model';

const reportId = 'comparisonTeacher';
const documentId = '';

export interface ComparisonTeacherData extends IsophiData {
  reportId: typeof reportId;
  child: Child;
  test: Test;
  testData: TestData[];
  legend: Legend[];
}

@Component({
  selector: 'app-comparison-teacher-report',
  templateUrl: './comparison-teacher-report.component.html',
  styleUrls: ['./comparison-teacher-report.component.scss'],
})
export class ComparisonTeacherReportComponent extends BaseReportComponent<ComparisonTeacherData> {
  public getRanges = getRanges;

  public sectionTypeEunm = sectionTypeEunm;

  public questionTypeEnum = questionTypeEnum;

  public counter = 0;

  public counter2 = 0;

  get reportId(): string {
    return reportId;
  }

  get documentId(): string {
    return documentId;
  }

  public checkNotesQuestions = checkNotesQuestions;

  public breakPoint(index: number, note: Section): boolean {
    let counter = 0;

    if (note.sectionType === this.sectionTypeEunm.QUESTION) {
      for (let i = 0; i < note.subSections.length; i++) {
        const val = note.subSections[i];

        if (note.subSections[i].questions.length > 1) {
          const filteredQuestions = val.questions.filter((val1) => val1.value === 'true');
          counter += filteredQuestions.length;
        } else if (val.questions.length === 1 && val.questions[0].value) {
          counter += val.questions[0].options.length;
        }

        if (counter >= 15) {
          break;
        }
      }
    }

    if (index === 1) {
      this.counter2 = counter;
    } else {
      this.counter = counter;
    }
    return Boolean(counter >= 15);
  }

  public checkFifthNote(notes: Section[]): boolean {
    let counter = 0;

    notes.forEach((note) => {
      if (note.sectionType === this.sectionTypeEunm.QUESTION) {
        for (let i = 0; i < note.subSections.length; i++) {
          const val = note.subSections[i];

          if (note.subSections[i].questions.length > 1) {
            const filteredQuestions = val.questions.filter((val1) => val1.value === 'true');
            counter += filteredQuestions.length;
          } else if (val.questions.length === 1 && val.questions[0].value) {
            counter += val.questions[0].options.length;
          }
        }
      }
    });

    return Boolean(counter >= 15);
  }
}
