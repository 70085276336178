// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  replaysSampleRate: 0,
  tracesSampleRate: 0,

  materialServer: 'https://api.isophi-material.doc.endevel.cz',
  sentryDSN: 'https://18efc0724d7141f2a294f0bc5e7dbe65@o1002766.ingest.sentry.io/4505286538887168',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
