<table class="container-fluid">
  <thead>
    <tr>
      <th>
        <ng-content select="[header]"></ng-content>
        <ng-content select="[profileTokensHeader]"></ng-content>
      </th>
    </tr>
  </thead>

  <tbody>
    <ng-content></ng-content>
  </tbody>

  <tfoot>
    <tr>
      <ng-content select="[profileTokensFooter]"></ng-content>
      <ng-container *ngIf="reportName === 'profileTokens'">
        <img src="assets/img/ptacek_navod2.svg" alt="logo" />
      </ng-container>
      <div>
        <ng-container *ngIf="reportName === 'profileTokens'">
          <img src="assets/img/ptacek_navod.svg" alt="logo" />
        </ng-container>
        <app-footer></app-footer>
      </div>
      <!-- <div class="page-number"></div> -->
    </tr>
  </tfoot>
</table>
