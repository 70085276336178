import { Component, Input } from '@angular/core';
import { CategoryResult } from 'isophi-pdfs-api';

@Component({
  selector: 'app-categories-with-color',
  templateUrl: './categories-with-color.component.html',
  styleUrls: ['./categories-with-color.component.scss']
})
export class CategoriesWithColorComponent {
  @Input() categoryResults: CategoryResult[];

  @Input() materialServer: string;

  @Input() comparison: boolean;
}
