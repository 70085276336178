<ng-container *transloco="let t">
  <div
    class="row"
    *ngIf="(child || groupReportInfo) && !multiReport; else multi"
    [class]="{ 'align-items-center': alignCenter || showIcon }"
  >
    <div class="header-info" [class]="alignCenter ? 'col-5' : game || comparison || group ? 'col-10' : showIcon ? 'col-11' : 'col-12'">
      <p class="fw-bold">
        {{ reportName }} {{ t('shared/report') }}
        <span *ngIf="typeOfReport" class="text-light bg-dark text-uppercase">{{ typeOfReport }}</span>
        <isophi-simple-badge
          *ngIf="test"
          [title]="test.shortTitle"
          [fontSize]="1"
          [bgColor]="test.color"
          [style]="{ '--bg-color': test.color, '--font-size': '1rem' }"
        >
        </isophi-simple-badge>
      </p>
      <p class="fw-normal">{{ subTitle }}</p>
    </div>
    <div *ngIf="alignCenter || showIcon" [class]="game || comparison || group ? 'col-2' : 'col-1'">
      <img class="img-fluid report-icon" [src]="iconUrl" alt="icon" />
    </div>
    <div
      *ngIf="!group; else groupReport"
      class="child-info"
      [class]="{
        'col-12': comparison,
        'col-6': !comparison && !polar,
        'col-5': !comparison && polar,
        'mt-5': !alignCenter,
        'align-left': !alignCenter
      }"
    >
      <figure *ngIf="showGenderIcon" [style.left]="comparison ? '-1%' : '44%'">
        <img class="img-fluid" [src]="getChildIconSrc(child)" alt="gender" />
      </figure>
      <p class="child-name" *ngIf="!qReport">{{ childFullName(child) }}</p>
      <p class="child-name" *ngIf="qReport">{{ child.firstName }}</p>
      <div class="row" *ngIf="comparison; else single">
        <div class="col-6" *ngFor="let tst of [testData, testData2]; let index = index">
          <p>
            {{ index + 1 }}. {{ t('shared/diagnostikDate') }} <span>{{ tst.finishedAt | date: 'dd. MM. yyyy' }}</span>
          </p>
          <p>
            {{ t('shared/ageAt') }} {{ index + 1 }}. {{ t('shared/diagnostics') }}
            <span><isophi-child-age [format]="childAgeFormat" [child]="child" [finishedAt]="tst.finishedAt"></isophi-child-age></span>
          </p>
        </div>
      </div>

      <ng-template #single>
        <div class="row">
          <div *ngIf="game; else diagnostik" class="col-12">
            <p>
              {{ t('shared/playingDate') }} <span>{{ scenarioCategoryResults.finishedAt | date: 'dd. MM. yyyy' }}</span>
            </p>
            <p>
              {{ t('shared/ageAt') }} {{ t('shared/gaming') }}
              <span
                ><isophi-child-age
                  [format]="childAgeFormat"
                  [child]="child"
                  [finishedAt]="scenarioCategoryResults.finishedAt"
                ></isophi-child-age
              ></span>
            </p>
          </div>
          <ng-template #diagnostik>
            <div class="col-12" *ngIf="!qReport">
              <p>
                {{ t('shared/diagnostikDate') }} <span>{{ testData.finishedAt | date: 'dd. MM. yyyy' }}</span>
              </p>
              <p>
                {{ t('shared/ageAt') }} {{ t('shared/diagnostics') }}
                <span
                  ><isophi-child-age [format]="childAgeFormat" [child]="child" [finishedAt]="testData.finishedAt"></isophi-child-age
                ></span>
              </p>
            </div>

            <div class="col-12" *ngIf="qReport">
              <p>
                {{ t('shared/qFrom') }} <span>{{ testData.finishedAt | date: 'dd. MM. yyyy' }}</span>
              </p>
              <p>
                {{ t('shared/ageAt') }} {{ t('shared/filling') }}
                <span
                  ><isophi-child-age [format]="childAgeFormat" [child]="child" [finishedAt]="testData.finishedAt"></isophi-child-age
                ></span>
              </p>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </div>

    <ng-template #groupReport>
      <div class="child-info col-5 mt-5 align-left">
        <div class="row">
          <div class="col-12">
            <p>
              {{ t('shared/groups') }}
              <span>
                <ng-container *ngFor="let group of groupReportInfo.groups; last as isLast">
                  {{ group.name }}{{ isLast ? '' : ', ' }}
                </ng-container>
              </span>
            </p>
            <p>
              {{ t('shared/childrenNumber') }} <span>{{ groupReportInfo.childrenCount }}</span>
            </p>
            <p>
              {{ t('shared/averageAge') }} <span>{{ roundAge(groupReportInfo.averageAge) }} let</span>
            </p>
            <p>
              {{ t('shared/reportDate') }}
              <span> {{ groupReportInfo.dateFrom | date: 'dd. MM. YYYY' }} - {{ groupReportInfo.dateTo | date: 'dd. MM. YYYY' }} </span>
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <ng-template #multi>
    <ng-container *ngIf="multiReport; else order">
      <div class="row multi" *ngIf="child" [class]="{ 'align-items-center': showIcon }">
        <div class="header-info" [class]="showIcon ? 'col-10' : 'col-12'">
          <p class="fw-bold">{{ reportName }} {{ t('shared/multi') }}{{ t('shared/report') }}</p>
          <p class="fw-normal">{{ subTitle }}</p>
        </div>
        <div *ngIf="showIcon" class="col-2">
          <img class="img-fluid" [src]="iconUrl" alt="icon" />
        </div>
        <div class="child-info mt-5 col-12 align-left">
          <figure *ngIf="showGenderIcon" [style.left]="'-1%'">
            <img class="img-fluid" [src]="getChildIconSrc(child)" alt="gender" />
          </figure>
          <p class="child-name">{{ childFullName(child) }}</p>
          <div class="row">
            <div class="col-12">
              <p>{{ t('shared/ageAt') }} {{ t('shared/diagnostics') }}</p>
            </div>
            <div class="col-4" *ngFor="let testType of testTypes(tests); let i = index">
              <ng-container *ngFor="let test of filterTests(tests, testType); let j = index">
                <p>
                  <span class="number-font">{{ count(tests, test) }}.</span>
                  <isophi-simple-badge
                    [title]="test.test.shortTitle"
                    [bgColor]="'#000'"
                    [style]="{ '--bg-color': '#000', '--font-size': '14px' }"
                    [ngStyle]="testType === 'D 3-4' ? { opacity: '0.4' } : testType === 'D 4-5' ? { opacity: '0.6' } : { opacity: '0.8' }"
                  >
                  </isophi-simple-badge>
                  <span class="multi-date"
                    ><isophi-child-age [format]="childAgeFormat" [child]="child" [finishedAt]="test.finishedAt"></isophi-child-age
                  ></span>
                </p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #order>
    <div class="row align-items-center ordered" *ngIf="orderdGroup">
      <div class="header-info col-11">
        <p class="fw-bold">{{ t('shared/zDiagnostik') }}</p>
        <p class="fw-normal">
          {{ t('shared/evaluationSummary') }}
          <isophi-simple-badge [title]="subTitle" [fontSize]="1" [style]="{ '--bg-color': '#000', '--font-size': '1rem' }" class="ms-3">
          </isophi-simple-badge>
        </p>
      </div>
      <div class="col-1">
        <img class="img-fluid" src="assets/img/iconsDefault/z-icon.png" alt="zIcon" />
      </div>
      <div class="child-info col-4 mt-5 align-left">
        <div class="row">
          <div class="col-12">
            <p>
              {{ t('shared/enroll') }}
              <span>
                <ng-container *ngFor="let schoolYear of schoolYears; last as isLast">
                  {{ schoolYear }}{{ isLast ? '' : ', ' }}
                </ng-container>
              </span>
            </p>
            <p>
              {{ t('shared/childrenNumber') }} <span>{{ numberOfChildren }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
