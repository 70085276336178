<ng-container *transloco="let t">
  <app-report-wrapper *ngIf="data">
    <app-universal-header-report
      [reportName]="t('basicGroup/reportName')"
      [typeOfReport]="t('shared/reportBasic')"
      [subTitle]="t('basicGroup/subTitle')"
      [groupReportInfo]="data.groupReportInfo"
      [test]="data.test"
      [group]="true"
      [showIcon]="true"
      [iconUrl]="'assets/img/icon_group.svg'"
      header
    ></app-universal-header-report>

    <div class="col-4 polar-chart">
      <isophi-categories-polar-area-chart
        [categories]="data.categoryResults"
        [materialServer]="materialServer"
        [evaluations]="data.legend"
        [puppeteerApp]="true"
      >
        <app-section-header class="mb-0" order="01" [text]="t('shared/header1')"></app-section-header>
        <h6 class="fw-bold text-end" [innerHTML]="t('shared/subHeader1')"></h6>
      </isophi-categories-polar-area-chart>
    </div>

    <tr class="row section-2">
      <td class="col-12">
        <app-section-header order="02" [text]="t('basicGroup/header2')"></app-section-header>
      </td>
      <td class="col-12 d-flex flex-column">
        <app-chart-hint class="d-flex justify-content-center" [evaluations]="data.legend"></app-chart-hint>
        <app-percentage class="d-flex justify-content-center"></app-percentage>
        <app-category-bar-chart
          *ngFor="let categoryResult of data.categoryResults"
          [categoryResult]="categoryResult"
          [iconUrl]="materialServer + categoryResult.category.activeIconUrl"
          [range]="getRanges(data.test.ranges, categoryResult)"
        ></app-category-bar-chart>
      </td>
    </tr>

    <tr class="row align-items-center section-break">
      <td class="col-3">
        <app-section-header order="03" [text]="t('basicGroup/header3')"></app-section-header>
      </td>
      <td class="col-9">
        <isophi-categories-radar-chart
          [categoryResults]="data.categoryResults"
          [materialServer]="materialServer"
          [typeOfReport]="'basic'"
          [puppeteerApp]="true"
        ></isophi-categories-radar-chart>
      </td>
    </tr>

    <tr class="row">
      <td class="col-12">
        <app-section-header order="04" [text]="t('basicGroup/header4')"></app-section-header>
      </td>
      <td class="col-12">
        <ul class="row mt-3 ms-4">
          <li class="col-4" *ngFor="let child of data.groupReportInfo.children">
            {{ childFullName(child) }} ({{ roundAge(child.resultAge) }} {{ t('basicGroup/age') }})
          </li>
        </ul>
      </td>
    </tr>

    <tr class="row section-break">
      <td class="col-12">
        <app-section-header order="05" [text]="t('basicGroup/header5')"></app-section-header>
      </td>
      <td class="col-12">
        <app-problematic-categories
          [problematicCategories]="data.problematicCategories"
          [materialServer]="materialServer"
        ></app-problematic-categories>
      </td>
    </tr>

    <div class="row last-section" [ngStyle]="{ 'margin-top': data.groupReportInfo.groups.length < 4 ? '18rem' : '20rem' }">
      <tr class="col-6" *ngFor="let problematicCategory of data.problematicCategories">
        <td>
          <h5 class="d-flex align-items-center fw-bold">
            <img class="img-fluid" [src]="materialServer + problematicCategory.category.activeIconUrl" alt="icon" />{{
              problematicCategory.category.name
            }}
            ({{ t('basicGroup/childrenNumber') }} {{ problematicCategory.childrenCount }})
          </h5>
          <ul class="ms-5">
            <li *ngFor="let child of problematicCategory.children">
              {{ childFullName(child) }}
            </li>
          </ul>
        </td>
      </tr>
    </div>
  </app-report-wrapper>
</ng-container>
