import { Component, Input } from '@angular/core';

/**
 * Reusable component for hexagon with image.
 */
@Component({
  selector: 'app-hexagon',
  templateUrl: './hexagon.component.html',
  styleUrls: ['./hexagon.component.scss']
})
export class HexagonComponent {
  @Input() public iconUrl: string;
}
