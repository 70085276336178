import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-wrapper',
  templateUrl: './report-wrapper.component.html',
  styleUrls: ['./report-wrapper.component.scss']
})
export class ReportWrapperComponent {
  @Input() public reportName?: string;
}
