import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';
import { QRCodeModule } from 'angularx-qrcode';

import { CoreChartsModule } from '@isophi/core-charts';
import { CoreUiModule } from '@isophi/core-ui';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReportListRouteComponent } from './core/components/report-list-route/report-list-route.component';
import { ProfileTokensComponent } from './document/profile-tokens/profile-tokens.component';
import { QrCodesComponent } from './document/qr-codes/qr-codes.component';
import { BasicGroupReportComponent } from './reports/basic-group-report/basic-group-report.component';
import { BasicParentReportComponent } from './reports/basic-parent-report/basic-parent-report.component';
import { BasicStemReportComponent } from './reports/basic-stem-report/basic-stem-report.component';
import { BasicTeacherReportComponent } from './reports/basic-teacher-report/basic-teacher-report.component';
import { ComparisonStemReportComponent } from './reports/comparison-stem-report/comparison-stem-report.component';
import { ComparisonTeacherReportComponent } from './reports/comparison-teacher-report/comparison-teacher-report.component';
import { DevelopmentMultiReportComponent } from './reports/development-multi-report/development-multi-report.component';
import { GameScreeningReportComponent } from './reports/game-screening-report/game-screening-report.component';
import { OrderedGroupReportComponent } from './reports/ordered-group-report/ordered-group-report.component';
import { QuestionnaireParentReportComponent } from './reports/questionnaire-parent-report/questionnaire-parent-report.component';
import { SharedModule } from './shared/shared.module';
import { TranslocoRootModule } from './transloco-root.module';
import { Router } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
    BasicParentReportComponent,
    QuestionnaireParentReportComponent,
    BasicTeacherReportComponent,
    ComparisonTeacherReportComponent,
    BasicGroupReportComponent,
    OrderedGroupReportComponent,
    BasicStemReportComponent,
    ComparisonStemReportComponent,
    GameScreeningReportComponent,
    ReportListRouteComponent,
    DevelopmentMultiReportComponent,
    QrCodesComponent,
    ProfileTokensComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    CoreUiModule,
    QRCodeModule,
    HttpClientModule,
    TranslocoRootModule,
    CoreChartsModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
