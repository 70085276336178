import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DataService } from '../../../../app/core/services/data.service';
import { environment } from '../../../../environments/environment';
import { IsophiData } from '../../../../server/data.model';

@Component({
  selector: 'app-base-report',
  templateUrl: './base-report.component.html',
  styleUrls: ['./base-report.component.scss'],
})
export abstract class BaseReportComponent<Data extends IsophiData> implements OnInit {
  data?: Data;

  materialServer = environment.materialServer;

  constructor(private dataService: DataService, private i18n: TranslocoService) {}

  abstract get reportId(): string;

  abstract get documentId(): string;

  ngOnInit(): void {
    this.data = this.dataService.getData(this.reportId, this.documentId);
    this.i18n.setDefaultLang(this.data.lang);
  }
}
