import { Category, ChildrenResult, Legend, OrderByEnum, Test } from 'isophi-pdfs-api';

export const children: ChildrenResult[] = [
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  },
  {
    categoryResults: [
      {
        percentScore: 100.0,
        category: {
          id: 1,
          uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
          name: 'Grafomotorika',
          activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
          color: '#E0937E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 3,
          uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
          name: 'Prostorová představivost',
          activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
          color: '#76CC7E'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 5,
          uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
          name: 'Zrakové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
          color: '#AADB6A'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 6,
          uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
          name: 'Sluchové vnímání',
          activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
          color: '#F79061'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 16,
          uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
          name: 'Matematické představy',
          activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
          color: '#F6687C'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      },
      {
        percentScore: 100.0,
        category: {
          id: 17,
          uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
          name: 'Vědomosti',
          activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
          inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
          color: '#EC6162'
        },
        evaluation: {
          code: 2,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          backgroundColor: '#99BF28',
          textColor: '#000000'
        }
      }
    ],
    percentScore: 100.0,
    child: {
      uuid: 'd36f7553-01c2-4a41-868c-a1bea8c3f202',
      firstName: 'Testing',
      lastName: 'Notes',
      birthDate: '2017-03-10',
      gender: 'male',
      revision: 0
    }
  }
];

export const orderTest: Test = {
  id: 9,
  uuid: 'dccc8e85-0ed1-4fae-ba0e-bc78b248c6e1',
  name: 'Pedagogická diagnostika pro zápis do ZŠ',
  title: 'Pedagogická diagnostika pro zápis do ZŠ',
  minAge: 1850,
  maxAge: 2737,
  age: 2190,
  etag: 'e4c2e8edac362acab7123654b9e73432',
  shortTitle: 'Z',
  color: '#E53149',
  productCodename: 'test_z',
  version: '1.0',
  ranges: {
    id: 7,
    evaluationType: 'basic',
    testRule: null,
    categoryRules: [
      {
        id: 63,
        categoryId: 16,
        categoryUuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
        ranges: [
          {
            id: 208,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 3,
            minPercent: null,
            maxPercent: null,
            code: 0
          },
          {
            id: 209,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 4,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
            code: 1
          },
          {
            id: 210,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 7,
            maxPoint: 10,
            minPercent: null,
            maxPercent: null,
            code: 2
          }
        ]
      },
      {
        id: 64,
        categoryId: 3,
        categoryUuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
        ranges: [
          {
            id: 211,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 4,
            minPercent: null,
            maxPercent: null,
            code: 0
          },
          {
            id: 212,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 5,
            maxPoint: 9,
            minPercent: null,
            maxPercent: null,
            code: 1
          },
          {
            id: 213,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 10,
            maxPoint: 14,
            minPercent: null,
            maxPercent: null,
            code: 2
          }
        ]
      },
      {
        id: 65,
        categoryId: 5,
        categoryUuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
        ranges: [
          {
            id: 214,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 3,
            minPercent: null,
            maxPercent: null,
            code: 0
          },
          {
            id: 215,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 4,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
            code: 1
          },
          {
            id: 216,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 7,
            maxPoint: 9,
            minPercent: null,
            maxPercent: null,
            code: 2
          }
        ]
      },
      {
        id: 66,
        categoryId: 1,
        categoryUuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
        ranges: [
          {
            id: 217,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 1,
            minPercent: null,
            maxPercent: null,
            code: 0
          },
          {
            id: 218,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 2,
            maxPoint: 3,
            minPercent: null,
            maxPercent: null,
            code: 1
          },
          {
            id: 219,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 4,
            maxPoint: 6,
            minPercent: null,
            maxPercent: null,
            code: 2
          }
        ]
      },
      {
        id: 67,
        categoryId: 6,
        categoryUuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
        ranges: [
          {
            id: 220,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 4,
            minPercent: null,
            maxPercent: null,
            code: 0
          },
          {
            id: 221,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 5,
            maxPoint: 7,
            minPercent: null,
            maxPercent: null,
            code: 1
          },
          {
            id: 222,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 8,
            maxPoint: 10,
            minPercent: null,
            maxPercent: null,
            code: 2
          }
        ]
      },
      {
        id: 68,
        categoryId: 17,
        categoryUuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
        ranges: [
          {
            id: 223,
            title: 'Nízká úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#DA2C38',
            minPoint: 0,
            maxPoint: 4,
            minPercent: null,
            maxPercent: null,
            code: 0
          },
          {
            id: 224,
            title: 'Snížená úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#FAB700',
            minPoint: 5,
            maxPoint: 7,
            minPercent: null,
            maxPercent: null,
            code: 1
          },
          {
            id: 225,
            title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
            shortTitle: null,
            textColor: '#000000',
            backgroundColor: '#99BF28',
            minPoint: 8,
            maxPoint: 11,
            minPercent: null,
            maxPercent: null,
            code: 2
          }
        ]
      }
    ],
    generalRule: {
      id: 9,
      ranges: [
        {
          id: 205,
          title: 'Nízká úroveň dovednosti',
          shortTitle: null,
          textColor: '#000000',
          backgroundColor: '#DA2C38',
          minPoint: null,
          maxPoint: null,
          minPercent: 0,
          maxPercent: 25,
          code: 0
        },
        {
          id: 206,
          title: 'Snížená úroveň dovednosti',
          shortTitle: null,
          textColor: '#000000',
          backgroundColor: '#FAB700',
          minPoint: null,
          maxPoint: null,
          minPercent: 26,
          maxPercent: 50,
          code: 1
        },
        {
          id: 207,
          title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
          shortTitle: null,
          textColor: '#000000',
          backgroundColor: '#99BF28',
          minPoint: null,
          maxPoint: null,
          minPercent: 50,
          maxPercent: 100,
          code: 2
        }
      ]
    }
  }
};

export const orderCategories: Category[] = [
  {
    id: 1,
    uuid: 'bf388000-3822-48a4-ae7c-5662ea1fdd9b',
    name: 'Grafomotorika',
    activeIconUrl: '/media/uploaded/tags/active/category/grafomotorika.svg',
    inactiveIconUrl: '/media/uploaded/tags/inactive/category/grafomotorika.svg',
    color: '#E0937E'
  },
  {
    id: 3,
    uuid: 'cbd9247b-cc13-4b8a-9994-1ff34493503d',
    name: 'Prostorová představivost',
    activeIconUrl: '/media/uploaded/tags/active/category/prostor.svg',
    inactiveIconUrl: '/media/uploaded/tags/inactive/category/prostor.svg',
    color: '#76CC7E'
  },
  {
    id: 5,
    uuid: '0d26d3a1-91ad-43d0-abbc-d6d1cacab2da',
    name: 'Zrakové vnímání',
    activeIconUrl: '/media/uploaded/tags/active/category/zrak.svg',
    inactiveIconUrl: '/media/uploaded/tags/inactive/category/zrak.svg',
    color: '#AADB6A'
  },
  {
    id: 6,
    uuid: 'a02e3d1f-0439-4bb1-b6ec-18ec10fa39fa',
    name: 'Sluchové vnímání',
    activeIconUrl: '/media/uploaded/tags/active/category/sluch.svg',
    inactiveIconUrl: '/media/uploaded/tags/inactive/category/sluch.svg',
    color: '#F79061'
  },
  {
    id: 16,
    uuid: 'd31778dc-170e-4294-b919-b7e140d5986b',
    name: 'Matematické představy',
    activeIconUrl: '/media/uploaded/tags/active/category/matematika.svg',
    inactiveIconUrl: '/media/uploaded/tags/inactive/category/matematika.svg',
    color: '#F6687C'
  },
  {
    id: 17,
    uuid: '1f7c2c5e-1343-4db3-ab69-5610743c2c7a',
    name: 'Vědomosti',
    activeIconUrl: '/media/uploaded/tags/active/category/vedomosti.svg',
    inactiveIconUrl: '/media/uploaded/tags/inactive/category/vedomosti.svg',
    color: '#EC6162'
  }
];

export const orderLegend: Legend[] = [
  {
    code: 2,
    title: 'Přiměřená nebo velmi dobrá úroveň dovednosti',
    backgroundColor: '#99BF28',
    textColor: '#000000'
  },
  {
    code: 1,
    title: 'Snížená úroveň dovednosti',
    backgroundColor: '#FAB700',
    textColor: '#000000'
  },
  {
    code: 0,
    title: 'Nízká úroveň dovednosti',
    backgroundColor: '#DA2C38',
    textColor: '#000000'
  }
];

export const schoolYears: string[] = ['2021/22'];

export const orderBy: OrderByEnum = 'average';
