<div class="hint" *transloco="let t">
  <p class="mb-2">{{ t('shared/legend') }}</p>
  <div class="d-flex flex-column">
    <div *ngFor="let evaluation of evaluations" class="d-flex">
      <p class="hint-color" [style]="{ 'background-color': evaluation.backgroundColor }"></p>
      <p class="hint-content">{{ evaluation.title }}</p>
    </div>
    <div *ngIf="lock" class="d-flex">
      <p class="hint-color" [style]="{ 'background-color': '#a3a3a3' }"></p>
      <p class="hint-content">{{ t('shared/notFilledIn') }} / {{ t('shared/premiumLicenseNeeded') }}</p>
    </div>
  </div>
</div>
