import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { childFullName } from '@isophi/core-ui';
import { CategoryResult, Child, Legend, OrderedGroupCategoryResult } from 'isophi-pdfs-api';

@Component({
  selector: 'app-children-sorted-chart',
  templateUrl: './children-sorted-chart.component.html',
  styleUrls: ['./children-sorted-chart.component.scss'],
})
export class ChildrenSortedChartComponent implements OnChanges {
  @Input() public categoryResults: CategoryResult[];

  @Input() public orderedGroupCategoryResults: OrderedGroupCategoryResult[]

  @Input() public child: Child;

  @Input() public index = 0;

  @Input() public evaluations: Legend[];

  @Input() public comparison: boolean;

  @Input() public materialServer: string;

  @Input() public typeZ: boolean;

  public childFullName = childFullName;

  public firstHalfcategories = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['categoryResults']) {
      this.filteredCategories();
    }
  }

  public getGenderIcon(gender: string): string {
    if (gender === 'male') {
      return '/assets/img/iconsDefault/boy.svg';
    } else if (gender === 'female') {
      return '/assets/img/iconsDefault/girl.svg';
    } else {
      return '/assets/img/iconsDefault/questionMark.svg';
    }
  }

  public filteredCategories(): void {
    let categories = [...this.categoryResults];

    categories = categories.sort((a, b) => b.evaluation.code - a.evaluation.code);

    this.firstHalfcategories = [].concat(this.evaluations.map((e) => categories.filter((val) => val.evaluation.code === e.code)));
  }
}
